import { Box, Grid, Stack, Typography } from "@mui/material";
import { FaFacebook, FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { HomeItem, HomeItemProps } from "./HomeItem";

export const HomeSocial = () => {
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Social media
        </Typography>
        {/* <Typography variant="body2" sx={{ textAlign: "center" }}>
          Information about events, photos and stories from the events.
        </Typography> */}
        {/* <Typography variant="body3" sx={{ textAlign: "center" }}>
          To join events you have to join our private group (see above).
        </Typography> */}
      </Grid>
      <Grid container xs={12} spacing={2} justifyContent="center">
        <Stack direction="row" gap={3}>
          {LINKS_2.map((itemProps) => (
            <Box key={itemProps.href}>
              <HomeItem {...itemProps} />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

const LINKS_2: HomeItemProps[] = [
  {
    href: "https://fb.me/wboard.games",
    icon: <FaFacebook />,
    iconColor: "#1877F2",
    text: "@wboard.games",
    description: "Facebook Page",
  },
  {
    href: "https://instagr.am/wboardgames",
    icon: <FaInstagram />,
    iconColor: "#E1306C",
    text: "@wboardgames",
    description: "Instagram Account",
  },
  {
    href: "https://t.me/wboardgames",
    icon: <FaTelegramPlane />,
    iconColor: "#229ED9",
    text: "@wboardgames",
    description: "Telegram Channel",
  },
];
