import { Box, Typography } from "@mui/material";
import { MeetupInfo } from "src/components/MeetupInfo";
import { PageTitle } from "../../components/PageTitle";
import { formatDateNoWrap } from "../../helpers/formatDate";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";

type Props = {
  application: Application;
  showChat?: boolean;
};

export const ApplicationDefaultView = ({ application, showChat }: Props) => {
  useTitle("Your Application");
  return (
    <>
      <PageTitle title="Your Application" />

      <Box sx={{ mt: -2, mb: 4 }}>
        {application.meetup && (
          <MeetupInfo meetup={application.meetup} showChat={showChat} />
        )}
      </Box>

      <Typography variant="body1">
        Your application for the <b>{application.meetup.name}</b> has status{" "}
        <b>"{application.status}"</b>.
      </Typography>
    </>
  );
};
