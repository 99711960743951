import { Stack } from "@mui/material";
import { GamesOnMeetupsWith } from "@pastukh-dm/wboard-games";
import {
  FieldValues,
  Control,
  ArrayPath,
  useFieldArray,
} from "react-hook-form";
import { VotingGameListItem } from "./VotingGameListItem";

export const VotingGameList = <T extends FieldValues>({
  games,
  control,
  fieldKey,
  disabled,
  secondary,
}: {
  control: Control<T>;
  games: GamesOnMeetupsWith<"game">[];
  fieldKey: ArrayPath<T>;
  disabled?: boolean;
  secondary?: boolean;
}) => {
  const { fields } = useFieldArray({
    control,
    name: fieldKey,
  });

  return (
    <Stack gap={1}>
      {fields.map((item, index) => (
        <VotingGameListItem
          games={games}
          control={control}
          fieldKey={fieldKey}
          disabled={disabled}
          secondary={secondary}
          index={index}
          item={item}
        />
      ))}
    </Stack>
  );
};
