import { Box } from "@mui/material";
import { CustomAccordion } from "../components/CustomAccordion";
import { PageTitle } from "../components/PageTitle";

export const ApplyingAndParticipatingPage = () => {
  return (
    <>
      <PageTitle title="Applying and Participating" />
      <CustomAccordion steps={APPLYING_AND_PARTICIPATING} />
    </>
  );
};
export const APPLYING_AND_PARTICIPATING = [
  {
    id: "step-1",
    name: "Step 1",
    title: "Apply for Meetup",
    shortDescription: "Submit your application.",
    longDescription:
      "Fill in the application form to show you want to join the meetup. While applying, add any games you'd like to include in the voting process.",
  },
  {
    id: "step-2",
    name: "Step 2",
    title: "Get Application Results",
    shortDescription: "Learn if you're accepted or waitlisted.",
    longDescription:
      "We'll check applications and accept or waitlist them. You'll be notified about the results.",
  },
  {
    id: "step-3",
    name: "Step 3",
    title: "Vote for Games",
    shortDescription: "Vote for your preferred games.",
    longDescription:
      "You'll get instructions on how to vote for games. You can vote for games from a list provided by the organizer and the ones added by applicants. You'll be assigned to one of the games you voted for.",
  },
  {
    id: "step-4",
    name: "Step 4",
    title: "Pay Deposit",
    shortDescription: "Secure your spot.",
    longDescription:
      "If you're accepted, you have 24 hours to pay a deposit to secure your spot. The deposit ensures you'll attend the event and can be donated or returned after your arrival. You'll receive the steps to do this.",
  },
  {
    id: "step-5",
    name: "Step 5",
    title: "Prepare & Attend the Meetup",
    shortDescription: "Get ready for the games and enjoy the meetup.",
    longDescription:
      "You'll be informed of the games you'll play before the meetup. Learn the rules by watching a video. Arrive on time at the meetup location, participate in the games, and have fun while adhering to the code of conduct.",
  },
  {
    id: "step-6",
    name: "Step 6",
    title: "Share Feedback & Decide on Deposit",
    shortDescription: "Give your opinion and make a decision about your deposit.",
    longDescription:
      "After the meetup, you'll be asked for feedback on the event and to make a decision about your deposit. You can either donate it to support future events or request a return. Feel free to share your thoughts during the meetup or afterwards.",
  },
];

