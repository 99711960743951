import { MeetupLevel } from "@pastukh-dm/wboard-games";

export const colorByLevel: Record<MeetupLevel, string> = {
  [MeetupLevel.LIGHT]: "#86E5F8",
  [MeetupLevel.MEDIUM]: "#86F9AB",
  [MeetupLevel.HEAVY]: "#FEC4D8",
};
export const midColorByLevel: Record<MeetupLevel, string> = {
  [MeetupLevel.LIGHT]: "#5893A3",
  [MeetupLevel.MEDIUM]: "#56A577",
  [MeetupLevel.HEAVY]: "#A8758E",
};
export const darkColorByLevel: Record<MeetupLevel, string> = {
  [MeetupLevel.LIGHT]: "#2A414D",
  [MeetupLevel.MEDIUM]: "#265143",
  [MeetupLevel.HEAVY]: "#512643",
};
