import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { MeetupInfo } from "src/components/MeetupInfo";
import { PageTitle } from "../../components/PageTitle";
import { useTitle } from "../../hooks/useTitle";
import { EmojiPeople, WavingHand, Warning } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddedGamesPreview } from "src/components/AddedGamesPreview";
import { ApplyGamesSection } from "src/components/ApplyGamesSection";
import { FormField } from "src/components/form/FormField";
import { ROUTES } from "src/consts/routes";
import {
  gameParamsByMeetupLevel,
  getReadableWeight,
} from "src/helpers/gameRange";
import { ApplicationWith } from "@pastukh-dm/wboard-games";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFetch } from "src/hooks/useFetch";
import { Meetup } from "src/types";
import { Link as RouterLink } from "react-router-dom";
import { isDateInFuture } from "src/helpers/formatDate";
import axios from "axios";

const schema = yup
  .object({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    application: yup.string().required(),
    libraryGames: yup.array().of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    ),
    bringGames: yup.array().of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    ),
  })
  .required();

export type ApplyFormData = yup.InferType<typeof schema>;

type Props = {
  application: ApplicationWith<"user" | "meetup">;
  refetch: () => void;
};

export const ApplicationVerifiedView = ({ application, refetch }: Props) => {
  useTitle("Finish your application");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ApplyFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      application: application.id as string,
      firstName: application.user.firstName || "",
      lastName: application.user.lastName || "",
    },
  });

  const { data } = useFetch<{ meetups: Meetup[]; prev: Meetup }>({
    url: "/meetups/open",
  });

  const meetups = data?.meetups;

  const availableMeetups = useMemo(
    () =>
      meetups
        ?.filter((item) => isDateInFuture(item.applyTo))
        .sort((a, b) => (a.startAt > b.startAt ? 1 : -1)),
    [meetups]
  );

  const selectedMeetup = useMemo(() => {
    return availableMeetups?.find((i) => i.id === application.meetup.id);
  }, [availableMeetups, application]);

  const gameParams =
    selectedMeetup?.level && gameParamsByMeetupLevel[selectedMeetup?.level];

  const mainGames = selectedMeetup?.games.filter((game) => !!game.isMainGame);
  const cooldownGames = selectedMeetup?.games.filter(
    (game) => !game.isMainGame
  );

  // useEffect(() => {
  //   const handler = (event: BeforeUnloadEvent) => {
  //     event.returnValue = true;
  //   };
  //   window.addEventListener("beforeunload", handler);
  //   return () => window.removeEventListener("beforeunload", handler);
  // }, []);

  const registered = useMemo(
    () => application.user.firstName || application.user.lastName,
    [application]
  );

  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ApplyFormData> = async (data) => {
    setError(undefined);
    setSuccess(undefined);
    setIsSubmitting(true);
    try {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        application: data.application,
        libraryGames:
          data.libraryGames && data.libraryGames.length > 0
            ? data.libraryGames.map((i) => i.value)
            : undefined,
        bringGames:
          data.bringGames && data.bringGames.length > 0
            ? data.bringGames.map((i) => i.value)
            : undefined,
      };
      await axios.post<{ application: { id: string } }>(
        `/applications/apply`,
        payload
      );
      refetch();
      setSuccess(true);
    } catch (e: any) {
      setError(
        e?.response?.data?.error ||
          "Something went wrong. Please try again. If problem still occurs, please contact me https://www.facebook.com/pastukh.dm"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle title="Finish your application" />
      <Box sx={{ mt: -2, mb: 4 }}>
        {application.meetup && (
          <MeetupInfo meetup={application.meetup as any} />
        )}
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ my: 4 }}>
          {!!registered ? (
            <>
              <Alert icon={<EmojiPeople />}>Good to see you back!</Alert>
            </>
          ) : (
            <>
              <Alert
                icon={<WavingHand />}
                color="info"
                // variant="outlined"
              >
                <AlertTitle>Welcome!</AlertTitle>
                You are applying for <b>the first time</b>. An additional
                information about you is needed
              </Alert>
            </>
          )}

          <Stack direction="row" gap={2} sx={{ my: 4 }}>
            <FormField
              control={control}
              errors={errors}
              name="firstName"
              label="First Name"
              disabled={isSubmitting}
            />
            <FormField
              control={control}
              errors={errors}
              name="lastName"
              label="Last Name"
              disabled={isSubmitting}
            />
          </Stack>
        </Box>
        <>
          <Box>
            <ApplyGamesSection
              meetup={selectedMeetup}
              control={control}
              errors={errors}
            />
            {gameParams && mainGames && mainGames.length > 0 && (
              <>
                <Typography variant="h6" fontSize={16} sx={{ mt: 2 }}>
                  Games in voting
                </Typography>
                <Box component="ul" sx={{ mt: 1, mb: 3 }}>
                  {mainGames && mainGames.length > 0 && (
                    <Box component="li" sx={{ mb: 0 }}>
                      <AddedGamesPreview
                        title={`Main games`}
                        weight={getReadableWeight(gameParams?.main.weight)}
                        games={mainGames}
                      />
                    </Box>
                  )}

                  {cooldownGames && cooldownGames.length > 0 && (
                    <Box component="li" sx={{ mb: 0 }}>
                      <AddedGamesPreview
                        title={`Cooldown games`}
                        weight={getReadableWeight(gameParams?.cooldown.weight)}
                        games={cooldownGames}
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </>
        <Alert
          variant="outlined"
          color="warning"
          icon={<Warning />}
          sx={{ my: 5 }}
        >
          {/* <AlertTitle>Please note</AlertTitle> */}
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            If your application is accepted you will have <b>24 hours</b> to
            send a <b>20 PLN</b> deposit.
          </Typography>
          <Typography variant="caption" display="block" sx={{ mb: 1 }}>
            Otherwise your application will be automatically canceled
          </Typography>
          {/* <Typography variant="caption" display="block" color="GrayText">
          You can send deposit via Revolut or bank transfer
        </Typography> */}
        </Alert>
        <>
          <Stack direction="column" gap={2} sx={{ mt: 4 }}>
            <Typography variant="caption">
              By submitting the form, you agree to the{" "}
              <b>
                <Link
                  component={RouterLink}
                  to={ROUTES.TermsAndConditions}
                  target="_blank"
                >
                  Terms and Conditions
                </Link>
              </b>{" "}
              and{" "}
              <b>
                <Link component={RouterLink} to={ROUTES.Code} target="_blank">
                  Code of Conduct
                </Link>
              </b>
              .
            </Typography>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              sx={{ px: "40px" }}
            >
              {isSubmitting ? "Applying..." : "Apply"}
            </Button>
          </Stack>
        </>
        {error && (
          <Alert severity="error" sx={{ my: 2 }}>
            Something went wrong. Please try again. If problem still occurs,
            please contact me https://www.facebook.com/pastukh.dm
          </Alert>
        )}
      </form>
    </>
  );
};
