import { School } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { MeetupInfo } from "src/components/MeetupInfo";
import { useFetch } from "src/hooks/useFetch";
import { PageTitle } from "../../components/PageTitle";
import { formatDateNoWrap } from "../../helpers/formatDate";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";

type Props = {
  application: Application;
};

export const ApplicationAssignedView = ({ application }: Props) => {
  const { data, isLoading } = useFetch<{
    meetupName: string;
    mainGames: any[];
    cooldownGames: any[];
  }>({
    url: `/applications/${application.id}/plan`,
  });
  useTitle("Your Application");
  return (
    <>
      <PageTitle title="Your Application" />

      <Box sx={{ mt: -2, mb: 4 }}>
        {application.meetup && <MeetupInfo meetup={application.meetup} showChat />}
      </Box>

      <Typography variant="body1" mb={2}>
        The plan for the the <b>{application.meetup.name}</b> is ready. Here is
        the results:
      </Typography>
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <Typography variant="h6" sx={{ textAlign: "left", mt: 4, mb: 4 }}>
            Main Games
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                {/* <TableCell>BGG ID</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Weight</TableCell>
                {/* <TableCell>Est. Duration (Min)</TableCell> */}
                {/* <TableCell>Players Count</TableCell> */}
                <TableCell>Owner</TableCell>
                <TableCell>Assigned Players</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.mainGames.map((item) => (
                <TableRow>
                  {/* <TableCell>{item.game.geekId}</TableCell> */}
                  <TableCell>{item.game.name}</TableCell>
                  <TableCell>{item.game.weight.toFixed(2)}</TableCell>
                  {/* <TableCell>{item.game.maxPlaytime}</TableCell> */}
                  {/* <TableCell>
                {item.players.length}/{item.game.maxPlayers}
              </TableCell> */}
                  <TableCell>{item.owner}</TableCell>
                  <TableCell>
                    {item.players
                      .sort((a: any) => (a.know ? -1 : 1))
                      .map((p: any) => (
                        <Typography variant="body2" noWrap>
                          {p.know ? (
                            <School
                              sx={{
                                fontSize: 14,
                                mr: 1,
                                color: "primary.main",
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: "14px",
                                mr: 1,
                                display: "inline-block",
                              }}
                            />
                          )}
                          {p.name}
                        </Typography>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography variant="h6" sx={{ textAlign: "left", mt: 4, mb: 4 }}>
            Cooldown Games
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                {/* <TableCell>BGG ID</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Weight</TableCell>
                {/* <TableCell>Est. Duration (Min)</TableCell> */}
                {/* <TableCell>Players Count</TableCell> */}
                <TableCell>Owner</TableCell>
                <TableCell>Max Players</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.cooldownGames.map((item) => (
                <TableRow>
                  {/* <TableCell>{item.game.geekId}</TableCell> */}
                  <TableCell>{item.game.name}</TableCell>
                  <TableCell>{item.game.weight.toFixed(2)}</TableCell>
                  {/* <TableCell>{item.game.maxPlaytime}</TableCell> */}
                  <TableCell>{item.owner}</TableCell>
                  <TableCell>{item.game.maxPlayers}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};
