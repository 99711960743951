import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Box,
} from "@mui/material";
import { grey, red, blueGrey } from "@mui/material/colors";
import dayjs from "dayjs";
import { Control, Controller } from "react-hook-form";
import { BsHexagon, BsHexagonFill } from "react-icons/bs";
import { Meetup } from "src/types";
import { colorByLevel, midColorByLevel } from "src/utils/colors";

type Props = {
  control: Control<{
    meetup: string;
    email: string;
  }>;
  availableMeetups: Meetup[];
};

export const SelectMeetup = ({ control, availableMeetups }: Props) => {
  return (
    <Controller
      name="meetup"
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormLabel>
            <Typography variant="subtitle2">
              {availableMeetups.length > 1
                ? "Select one of the available meetups:"
                : "You are applying to join:"}
            </Typography>
          </FormLabel>
          <RadioGroup
            {...field}
            value={field.value || null}
            onChange={(e, value) => field.onChange(value)}
          >
            {availableMeetups.map((meetup) => (
              <FormControlLabel
                key={meetup.id}
                value={meetup.id}
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        height: 32,
                        width: 32,
                      },
                      "&.Mui-checked": {
                        color: colorByLevel[meetup.level],
                      },
                    }}
                    icon={<BsHexagon />}
                    checkedIcon={<BsHexagonFill />}
                  />
                }
                sx={{ my: 1, justifyContent: "" }}
                label={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={4}
                  >
                    <Box
                      sx={{
                        ".Mui-checked + .MuiFormControlLabel-label & > *": {
                          color: midColorByLevel[meetup.level],
                        },
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          mr: 1,
                        }}
                      >
                        {meetup.name}
                      </Box>
                      <Box
                        sx={{
                          display: "block",
                          fontSize: "0.8em",
                          mr: 1,
                          color: grey[700],
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline",
                            textTransform: "uppercase",
                          }}
                        >
                          {dayjs(meetup.startAt).format("ddd, MMM D")}
                        </Box>{" "}
                        at{" "}
                        <Box
                          sx={{
                            display: "inline",
                            textTransform: "uppercase",
                          }}
                        >
                          {dayjs(meetup.startAt).format("HH:mm")}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "25%",
                          fontSize: "0.8em",
                          color: `${
                            dayjs(meetup.applyTo).isBefore(dayjs().add(2, "d"))
                              ? red[600]
                              : blueGrey[600]
                          } !important`,
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Application period ends{" "}
                        <b>in {dayjs(meetup.applyTo).fromNow(true)}</b>
                      </Box>
                    </Box>
                  </Stack>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
