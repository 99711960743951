import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "./consts/routes";
import { HomePage } from "./pages/HomePage";
import { NoMatchPage } from "./pages/NoMatchPage";
import { Layout } from "./components/Layout";
import { ApplyPage } from "./pages/apply/ApplyPage";
import { UnsubscribePage } from "./pages/UnsubscribePage";
import { SubscribePage } from "./pages/SubscribePage";
import { ApplicationPage } from "./pages/application/ApplicationPage";
import { TermsAndConditionsPage } from "./pages/TermsAndConditionsPage";
import { CodeOfConductPage } from "./pages/CodeOfConductPage";
import { ApplyingAndParticipatingPage } from "./pages/ApplyingAndParticipatingPage";
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: { main: "#199cd2" },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<Layout />}>
          <Route path={ROUTES.Home} element={<HomePage />} />
          <Route path={ROUTES.Apply}>
            <Route path="" element={<ApplyPage />} />
          </Route>
          <Route path={ROUTES.Application} element={<ApplicationPage />} />

          <Route path={ROUTES.Unsubscribe} element={<UnsubscribePage />} />
          <Route path={ROUTES.Subscribe} element={<SubscribePage />} />
          <Route
            path={ROUTES.TermsAndConditions}
            element={<TermsAndConditionsPage />}
          />
          <Route path={ROUTES.Code} element={<CodeOfConductPage />} />
          <Route
            path={ROUTES.Participating}
            element={<ApplyingAndParticipatingPage />}
          />

          <Route path="*" element={<NoMatchPage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
