import { ErrorMessage } from "@hookform/error-message";
import { TextField } from "@mui/material";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from "react-hook-form";

export const FormField = <T extends FieldValues>({
  name,
  label,
  placeholder,
  control,
  helperText,
  disabled,
  errors,
  onKeyPress,
  type,
}: {
  name: Path<T>;
  label?: string;
  helperText?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  placeholder?: string;
  control: Control<T>;
  disabled?: boolean;
  errors: FieldErrors<T>;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          value={field.value}
          onChange={field.onChange}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          variant="outlined"
          helperText={
            !!errors[name] ? (
              <ErrorMessage errors={errors} name={name as any} />
            ) : (
              helperText
            )
          }
          error={!!errors[name]}
          onKeyPress={onKeyPress}
          fullWidth
        />
      )}
    />
  );
};
