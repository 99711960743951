import {
  Favorite,
  FavoriteBorder,
  School,
  SchoolOutlined,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { Stack, Box, Typography, Button, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  GameModel,
  GameVoteExperience,
  GamesOnMeetupsWith,
  GameVoteInterest,
} from "@pastukh-dm/wboard-games";
import { ReactNode } from "react";
import {
  FieldValues,
  Control,
  ArrayPath,
  Controller,
  FieldPath,
  FieldArrayWithId,
  useWatch,
} from "react-hook-form";

export const VotingGameListItem = <T extends FieldValues>({
  games,
  control,
  fieldKey,
  disabled,
  secondary,
  index,
  item,
}: {
  control: Control<T>;
  games: GamesOnMeetupsWith<"game">[];
  fieldKey: ArrayPath<T>;
  disabled?: boolean;
  secondary?: boolean;
  item: FieldArrayWithId<T>;
  index: number;
}) => {
  const interest = useWatch({
    name: `${fieldKey}.${index}.interest`,
    control: control as any,
  });
  const getGeekInfo = (key: keyof GameModel, gameId: string) => {
    return games?.find((i) => i.gameId === gameId)?.game?.[key] || "";
  };
  const tryGetWeight = (gameId: string) => {
    try {
      return (getGeekInfo("weight", gameId) as number).toFixed(2);
    } catch (e) {
      return "-";
    }
  };
  const thumbnail = getGeekInfo("thumbnail", (item as any).gameId) as string;
  const theme = useTheme();
  const colorByInterest: Record<GameVoteInterest, string> = {
    [GameVoteInterest.DISLIKE]: theme.palette.error.main,
    [GameVoteInterest.LIKE]: theme.palette.success.main,
    [GameVoteInterest.LOVE]: theme.palette.secondary.main,
    [GameVoteInterest.NEUTRAL]: grey[300],
  };

  return (
    <Stack direction="row" gap={0}>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          padding: "0",
          paddingLeft: "64px",
          height: "64px",
          outline: `1px solid ${grey[300]}`,
          overflow: "hidden",
        }}
        gap={2}
      >
        <Box
          component="img"
          src={thumbnail}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "calc(100% - 128px)",
            filter: "blur(5px) opacity(15%)",
          }}
        />
        <Box
          sx={{
            width: 64,
            height: 64,
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Box
            component="img"
            src={getGeekInfo("thumbnail", (item as any).gameId) as string}
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 2,
            p: "0px 10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "15px",
              maxHeight: "30px",
              overflow: "hidden",
            }}
          >
            {getGeekInfo("name", (item as any).gameId) as string}
            <br />
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: "12px", color: "#888" }}
          >
            Weight: <b>{tryGetWeight((item as any).gameId)}</b>
          </Typography>
        </Box>
        <Stack direction="row">
          {!secondary &&
            [GameVoteInterest.LIKE, GameVoteInterest.LOVE].includes(
              interest
            ) && (
              <Controller
                name={`${fieldKey}.${index}.experience` as FieldPath<T>}
                control={control}
                defaultValue={(item as any).experience}
                render={({ field }) => (
                  <VotingButton
                    tooltip="Know"
                    color="info"
                    field={field}
                    on={{
                      value: GameVoteExperience.YES,
                      icon: <School fontSize={size} />,
                    }}
                    off={{
                      value: GameVoteExperience.NO,
                      icon: <SchoolOutlined fontSize={size} />,
                    }}
                  />
                )}
              />
            )}
          <Controller
            name={`${fieldKey}.${index}.interest` as FieldPath<T>}
            control={control}
            defaultValue={(item as any).interest}
            render={({ field }) => (
              <VotingActions
                field={field}
                disabled={disabled}
                secondary={secondary}
                showLove={[
                  GameVoteInterest.LIKE,
                  GameVoteInterest.LOVE,
                ].includes(interest)}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const size = "medium";
const VotingActions = ({
  field,
  disabled,
  secondary,
  showLove = true,
}: {
  field: any;
  disabled?: boolean;
  secondary?: boolean;
  showLove?: boolean;
}) => {
  return (
    <Stack direction="row" gap={0} sx={{}}>
      {!secondary && showLove && (
        <VotingButton
          tooltip="Love"
          field={field}
          color="secondary"
          on={{
            value: GameVoteInterest.LOVE,
            icon: <Favorite fontSize={size} />,
          }}
          off={{
            value: GameVoteInterest.NEUTRAL,
            icon: <FavoriteBorder fontSize={size} />,
          }}
        />
      )}

      <VotingButton
        tooltip="Like"
        field={field}
        color="success"
        on={{
          value: GameVoteInterest.LIKE,
          // also: GameVoteInterest.LOVE,
          icon: <ThumbUp fontSize={size} />,
        }}
        off={{
          value: GameVoteInterest.NEUTRAL,
          icon: <ThumbUpOutlined fontSize={size} />,
        }}
      />
      <VotingButton
        tooltip="Dislike"
        field={field}
        color="error"
        on={{
          value: GameVoteInterest.DISLIKE,
          icon: <ThumbDown fontSize={size} />,
        }}
        off={{
          value: GameVoteInterest.NEUTRAL,
          icon: <ThumbDownOutlined fontSize={size} />,
        }}
        disabled={disabled}
      />
    </Stack>
  );
};

const VotingButton = <T = GameVoteInterest,>({
  field,
  on,
  off,
  tooltip,
  color,
  disabled,
}: {
  field: any;
  tooltip: string;
  color: "error" | "warning" | "primary" | "secondary" | "info" | "success";
  on: {
    value: T;
    also?: T;
    icon: ReactNode;
  };
  off: {
    value: T;
    icon: ReactNode;
  };
  disabled?: boolean;
}) => (
  // <Tooltip title={tooltip}>
  <Button
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      const newValue = field.value !== on.value ? on.value : off.value;
      if (!!disabled && newValue === ("DISLIKE" as any)) {
        return;
      }
      field.onChange(newValue);

      // field.onChange(on.value);
    }}
    sx={{
      // border: "1px solid #f00",
      borderRadius: 0,
      height: "64px",
      margin: "0",
      padding: "0",
      minWidth: "0",
      width: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      opacity: disabled && field.value !== on.value ? 0.25 : 1,

      color: field.value === on.value ? `#fff` : `${color}.main`,

      bgcolor: field.value === on.value ? `${color}.main` : "#fff",
      "&:hover": {
        color: field.value === on.value ? `#fff` : `${color}.main`,
        bgcolor: field.value === on.value ? `${color}.main` : "#fff",
      },
    }}
  >
    <Box>
      {field.value === on.value || field.value === on.also ? on.icon : off.icon}
    </Box>
    <Typography
      sx={{ fontSize: "10px" }}
      // color={ : undefined}
    >
      {tooltip}
    </Typography>
  </Button>
  // </Tooltip>
);

// enum GameListMode {
//   INITIAL,
//   DETAILED,
// }
