import { Typography, Box } from "@mui/material";
import { MainGameText } from "src/components/typography";
import {
  gameParamsByMeetupLevel,
  getReadableWeight,
} from "src/helpers/gameRange";
import { Meetup } from "src/types/Meetup";
import { midColorByLevel } from "src/utils/colors";

export const ApplyMeetupInfo = ({ meetup }: { meetup: Meetup }) => {
  const gameParams = meetup?.level && gameParamsByMeetupLevel[meetup.level];
  return (
    <Typography variant="body2" sx={{ mb: 0 }}>
      This is{" "}
      <Box
        sx={{
          display: "inline",
          color: meetup?.level ? midColorByLevel[meetup.level] : undefined,
          fontWeight: 700,
        }}
      >
        {meetup?.level.toUpperCase()}
      </Box>{" "}
      level meetup with
      {gameParams && (
        <>
          {/* <br /> */} <MainGameText plural /> that have weight{" "}
          <Box
            sx={{
              display: "inline",
              color: meetup?.level ? midColorByLevel[meetup.level] : undefined,
              fontWeight: 700,
            }}
          >
            {getReadableWeight(gameParams?.main.weight)}
          </Box>
          .
        </>
      )}
    </Typography>
  );
};
