import { Alert, Typography } from "@mui/material";
import { KnowText, LoveText, LikeText, DislikeText } from "./typography";

export const VotingHelp = () => (
  <>
    <Typography variant="body2" mb={2} fontWeight={700}>
      <KnowText /> – you have played it before
      <br />
      <Typography variant="caption">
        VERY HIGH chance you will be assigned to it
      </Typography>
    </Typography>
    <Typography variant="body2" mb={2} fontWeight={700}>
      <LoveText /> – you are dreaming to play it
      <br />
      <Typography variant="caption">
        HIGHER chance to be assigned to it
      </Typography>
    </Typography>
    <Typography variant="body2" mb={2} fontWeight={700}>
      <LikeText /> – you would like to play it
      <br />
      <Typography variant="caption">
        There is chance to be assigned to it
      </Typography>
    </Typography>
    <Typography variant="body2" mb={2} fontWeight={700}>
      <DislikeText /> – you don't want to play it <br />
      <Typography variant="caption">
        You will never be assigned to it
      </Typography>
    </Typography>
    <Alert color="warning">
      <Typography variant="caption">
        You may be assigned to game you didn't vote,{" "}
        <b>but you will never be assigned to a game you DISLIKED</b>
      </Typography>
    </Alert>
  </>
);
