import { Alert, Box, Typography } from "@mui/material";
import { MeetupInfo } from "src/components/MeetupInfo";
import { Meetup } from "src/types";
import { Contacts } from "../HomePage";

export const ApplyStepNoMeetups = ({
  previousMeetup,
}: {
  previousMeetup?: Meetup;
}) => {
  return (
    <>
      <Alert color="warning" sx={{ mb: 4 }}>
        <Typography variant="body2">
          No meetups to apply at this moment.
        </Typography>
      </Alert>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Last meetup:
      </Typography>
      {previousMeetup && <MeetupInfo meetup={previousMeetup} previous />}

      <Box sx={{ mt: 12 }}>
        <Typography variant="body2" sx={{ mb: 3, textAlign: "center" }}>
          You can join <b>Enthusiasts Community</b> stay informed about next meetups:
        </Typography>

        <Contacts />
      </Box>
    </>
  );
};
