import { ExpandMore } from "@mui/icons-material";
import {
  Typography,
  Accordion,
  AccordionSummary,
  Stack,
  AccordionDetails,
} from "@mui/material";
import { useState } from "react";

export const CustomAccordion = ({
  title,
  steps,
}: {
  title?: string;
  steps: {
    id: string;
    name?: string;
    title: string;
    shortDescription?: string;
    longDescription: string;
  }[];
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {title && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      {steps.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            id={`${item.id}-header`}
          >
            {item.name && (
              <Typography
                variant="overline"
                alignSelf="center"
                mr={2}
                color="GrayText"
                fontWeight={200}
              >
                {item.name}
              </Typography>
            )}

            <Stack>
              <Typography variant="subtitle2" fontWeight={700}>
                {item.title}
              </Typography>
              {item.shortDescription && (
                <Typography variant="caption" color="GrayText">
                  {item.shortDescription}
                </Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{item.longDescription}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
