import { WhatsApp } from "@mui/icons-material";
import { Stack, Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import { BsHexagonFill } from "react-icons/bs";
import { Meetup } from "src/types";
import { colorByLevel, midColorByLevel } from "src/utils/colors";

export const MeetupInfo = ({
  meetup,
  showChat,
  previous,
}: {
  meetup: Pick<Meetup, "level" | "name" | "startAt" | "address" | "chatLink">;
  showChat?: boolean;
  previous?: boolean;
}) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ alignItems: "center", flexWrap: "wrap" }}
    >
      <Box
        sx={{
          color:
            meetup.level && !previous ? colorByLevel[meetup.level] : grey[500],
        }}
      >
        <BsHexagonFill size={32} />
      </Box>
      <Box sx={{ flex: "auto" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Box
            sx={{
              color:
                meetup.level && !previous
                  ? midColorByLevel[meetup.level]
                  : grey[500],
              lineHeight: 1.5,
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                mr: 1,
              }}
            >
              {meetup.name}
            </Box>
            {meetup.startAt && (
              <Box
                sx={{
                  display: "block",
                  fontSize: "0.8em",
                  mr: 1,
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    display: "inline",
                    textTransform: "uppercase",
                  }}
                >
                  {dayjs(meetup.startAt).format("ddd, MMM D")}
                </Box>{" "}
                at{" "}
                <Box
                  sx={{
                    display: "inline",
                    textTransform: "uppercase",
                  }}
                >
                  {dayjs(meetup.startAt).format("HH:mm")}
                </Box>
              </Box>
            )}
            {meetup.address && (
              <Box
                sx={{
                  width: "25%",
                  fontSize: "0.8em",
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  color: grey[600],
                }}
              >
                {meetup.address}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>

      {showChat && meetup.chatLink && (
        <Button
          size="small"
          variant="contained"
          href={meetup.chatLink}
          target="_blank"
          startIcon={<WhatsApp />}
          sx={{
            // flex: "200px",
            width: "150px",
            background: "#2BB741",
            // my: 2,
            // alignSelf: "flex-start",
            ml: 5,
            // justifySelf: "flex-start",
            ":hover": { background: "#25D366" },
            whiteSpace: "nowrap",
          }}
        >
          Meetup chat
        </Button>
      )}
    </Stack>
  );
};
