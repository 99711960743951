import { Box, CircularProgress, Typography } from "@mui/material";

import { ReactNode } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { NoMatchPage } from "../NoMatchPage";
import { useFetch } from "../../hooks/useFetch";
import { Application } from "../../types/Application";
import { ApplicationAppliedView } from "./ApplicationAppliedView";
import { ApplicationDefaultView } from "./ApplicationDefaultView";
import { ApplicationAcceptedView } from "./ApplicationAcceptedView";
import { ApplicationConfirmedView } from "./ApplicationConfirmedView";
import { ApplicationDepositedView } from "./ApplicationDepositedView";
import { ApplicationVotingView } from "./ApplicationVotingView";
import { ApplicationFeedbackRequestedView } from "./ApplicationFeedbackRequestedView";
import { ROUTES } from "../../consts/routes";
import { ApplicationAssignedView } from "./ApplicationAssignedView";
import { ApplicationStatus } from "@pastukh-dm/wboard-games";
import { ApplicationVerifiedView } from "./ApplicationVerifiedView";
import { ApplyStepSuccess } from "../apply/ApplyStepSuccess";

export const ApplicationPage = () => {
  const { applicationId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const {
    data,
    error: fetchError,
    isLoading,
    refetch,
  } = useFetch<Application & { user: { name: string; firstName: string } }>({
    url: `/applications/${applicationId}?verify=${searchParams.get("verify")}`,
  });

  if (isLoading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (fetchError || !data) {
    return <NoMatchPage />;
  }

  const viewByStatus: Partial<Record<ApplicationStatus, () => ReactNode>> = {
    [ApplicationStatus.CREATED]: () => (
      <ApplyStepSuccess email="your email address" />
    ),
    [ApplicationStatus.VERIFIED]: () => (
      <ApplicationVerifiedView application={data as any} refetch={refetch} />
    ),
    [ApplicationStatus.APPLIED]: () => (
      <ApplicationAppliedView application={data} />
    ),
    [ApplicationStatus.ACCEPTED]: () => (
      <ApplicationAcceptedView application={data} refetch={refetch} />
    ),
    [ApplicationStatus.WAITLISTED]: () => (
      <ApplicationDefaultView application={data} />
    ),
    [ApplicationStatus.CONFIRMED]: () => (
      <ApplicationConfirmedView application={data} refetch={refetch} />
    ),
    [ApplicationStatus.DEPOSITED]: () => (
      <ApplicationDepositedView application={data} refetch={refetch} />
    ),
    [ApplicationStatus.VOTING]: () => (
      <ApplicationVotingView application={data} refetch={refetch} />
    ),
    [ApplicationStatus.VOTED]: () => (
      <ApplicationDefaultView application={data} showChat />
    ),
    [ApplicationStatus.GAME_ASSIGNED]: () => (
      <ApplicationAssignedView application={data} />
    ),
    [ApplicationStatus.FEEDBACK_REQUESTED]: () => (
      <ApplicationFeedbackRequestedView application={data} refetch={refetch} />
    ),
  };
  const view = viewByStatus[data.status];
  return (
    <>
      {view ? view() : <ApplicationDefaultView application={data} />}

      {![
        ApplicationStatus.FEEDBACK_REQUESTED,
        ApplicationStatus.FEEDBACK_RECEIVED,
      ].includes(data.status) && (
        <Typography variant="body2" sx={{ mt: 8 }}>
          Are you feeling unsure about the application process? Go to{" "}
          <Link to={ROUTES.Participating} target="_blank">
            Applying and Participating
          </Link>{" "}
        </Typography>
      )}
    </>
  );
};
