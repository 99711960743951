import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren, ReactNode } from "react";

export const CustomCollapse = ({
  title,
  children,
}: PropsWithChildren<{ title: string | ReactNode }>) => {
  const theme = useTheme();
  return (
    <Accordion
      square
      sx={{
        boxShadow: "none",
        border: `1px solid ${grey[200]}, background: grey[50]`,
        m: 0,
        p: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          m: 0,
          p: 0,
          minHeight: 0,
          justifyContent: "flex-start",
          color: theme.palette.primary.main,
          "&.Mui-expanded": { m: 0, p: 0, minHeight: 0 },
          "&.Mui-expanded .MuiAccordionSummary-content": {
            m: 0,
            p: 0,
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
            p: 0,
            flexGrow: "initial",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: "inherit",
          },
        }}
      >
        <Typography variant="body2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 0, p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};
