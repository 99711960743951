import { Typography, Stack } from "@mui/material";
import { GameModel } from "@pastukh-dm/wboard-games";
import { useMemo } from "react";
import { Control, FieldErrors } from "react-hook-form";
import { gameParamsByMeetupLevel } from "src/helpers/gameRange";
import { useFetch } from "src/hooks/useFetch";
import { Meetup } from "src/types/Meetup";
import { AddGamesField } from "./AddGamesField";
import { ApplyFormData } from "src/pages/application/ApplicationVerifiedView";

export const ApplyGamesSection = ({
  meetup,
  control,
  errors,
}: {
  meetup?: Meetup;
  control: Control<ApplyFormData>;
  errors: FieldErrors<ApplyFormData>;
}) => {
  const { data: libraryGames } = useFetch<GameModel[]>({
    url: "/games/library",
  });

  const gameParams = meetup?.level
    ? gameParamsByMeetupLevel[meetup.level]
    : undefined;

  const libraryOptions = useMemo(
    () =>
      libraryGames
        ?.filter((game) => {
          if (
            !gameParams?.main.weight.from ||
            !gameParams?.main.weight.to ||
            !game.weight
          ) {
            return true;
          }
          const res =
            gameParams.main.weight.from <= game.weight &&
            game.weight <= gameParams.main.weight.to;
          return res;
        })
        .sort((a, b) => (a.weight && b.weight && a.weight < b.weight ? 1 : -1))
        .map((game) => {
          return {
            value: game.geekId.toString(),
            label: `${
              game.name || game.geekId.toString()
            } (${game.weight?.toFixed(2)})`,
          };
        }) || [],
    [libraryGames, gameParams]
  );

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Games
      </Typography>
      <Stack gap={2}>
        <AddGamesField
          title="Do you want add games from WBG collection?"
          buttonText="Add games"
          fieldName="libraryGames"
          fieldLabel="Games from WBG collection"
          control={control}
          errors={errors}
          options={libraryOptions}
        />
        <AddGamesField
          title="Do you want to bring your games?"
          buttonText="Bring games"
          fieldLabel="Your games"
          fieldName="bringGames"
          control={control}
          errors={errors}
          options={[]}
          searchBGG
        />
      </Stack>
    </>
  );
};
