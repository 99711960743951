import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { PageTitle } from "../../components/PageTitle";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";
import { yupResolver } from "@hookform/resolvers/yup";
import { CopyToClipboardCell } from "../../components/CopyToClipboardCell";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { useSubmit } from "../../hooks/useSubmit";
import { DepositWarning } from "src/components/DepositWarning";
import { MeetupInfo } from "src/components/MeetupInfo";
import { PriorityHigh } from "@mui/icons-material";
import { BsCreditCardFill, BsHexagon, BsHexagonFill } from "react-icons/bs";
import { SiRevolut } from "react-icons/si";
import { ReactNode } from "react";
import { orange, red } from "@mui/material/colors";

type Props = {
  application: Application & {
    user: { name?: string; firstName?: string; lastName?: string };
  };
  refetch: () => void;
};
const schema = yup.object().shape({
  transferMethod: yup.string().required("Choose a transfer method"),
  transferSent: yup.boolean().oneOf([true], "Send a deposit"),
  // addGames: yup.boolean().required(),
  // games: yup.array().of(
  //   yup.object().shape({
  //     name: yup.string(),
  //     // .min(3, "Game name must be at least 3 characters long")
  //     // .required("Game name is required"),
  //   })
  // ),
  name: yup
    .string()
    .matches(/^\S*$/, "Whitespace is not allowed")
    .required("Required"),
});
type FormData = yup.InferType<typeof schema>;

export const ApplicationConfirmedView = ({ application, refetch }: Props) => {
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      // transferMethod: 'BANK'
      name: application.user.name || application.user.firstName,
    },
    resolver: yupResolver(schema),
  });
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "games",
  // });

  const { submit } = useSubmit<{
    transferMethod: "REVOLUT" | "BANK";
    games?: string[];
    transferAt: Date;
    name: string;
  }>({
    url: `/applications/${application.id}/deposit`,
  });

  const handleFormSubmit = async (data: FormData) => {
    await submit({
      transferMethod: data.transferMethod,
      // games:
      //   data.addGames && data.games && data.games.length > 0
      //     ? data.games.map((i) => i.name)
      //     : undefined,
      transferAt: data.transferSent ? new Date() : undefined,
      name: data.name,
    });

    refetch();
  };

  const transferMethod = watch("transferMethod");
  const transferSent = watch("transferSent");
  const name = watch("name");
  // const addGames = watch("addGames");

  useTitle("Your Application");
  const warning = (
    <Alert icon={<PriorityHigh />} color="warning" sx={{ my: 2 }}>
      <b>
        1 application = 1 deposit. <br />
      </b>
      Do not send deposit for several meetups or people.
    </Alert>
  );
  const noteRow = (
    <TableRow>
      <TableCell sx={{ color: orange[800] }}>Note/Title:</TableCell>
      <TableCell sx={{ fontWeight: 700, color: orange[800] }}>
        <CopyToClipboardCell
          content={`${
            application.meetup.shortId || application.meetup.name
          } - ${name || application.user.name || application.user.firstName}`}
        />
      </TableCell>
    </TableRow>
  );
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <PageTitle title="Your Application" />

        <Box sx={{ mt: -2, mb: 4 }}>
          {application.meetup && (
            <MeetupInfo meetup={application.meetup} showChat />
          )}
        </Box>

        <Box sx={{ mb: 4 }}>
          <TextField
            {...register("name")}
            label="Display Name"
            helperText={
              !!errors.name ? (
                <ErrorMessage errors={errors} name="name" />
              ) : (
                <>Write your given name, shortened name, or chosen name.</>
              )
            }
            error={!!errors.name}
            fullWidth
          />
        </Box>

        <Typography variant="body1">Choose transfer method:</Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Controller
            name="transferMethod"
            control={control}
            defaultValue=""
            rules={{ required: "Select a transfer type" }}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row" gap={2} justifyContent="center">
                  <RadioOption
                    value="BANK"
                    label="Bank Transfer"
                    icon={<BsCreditCardFill />}
                  />
                  <RadioOption
                    value="REVOLUT"
                    label="Revolut"
                    icon={<SiRevolut />}
                  />
                </Stack>
              </RadioGroup>
            )}
          />

          <ErrorMessage
            errors={errors}
            name="transferMethod"
            render={({ message }) => (
              <Typography color="error" variant="body2">
                {message}
              </Typography>
            )}
          />
        </Box>
        {transferMethod === "BANK" ? (
          <>
            <Typography variant="subtitle1">
              Send the deposit using the details:
            </Typography>
            {warning}
            <Card
              sx={{
                my: 2,
                borderColor: "#ddd",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            >
              <Table sx-={{ my: 5 }}>
                <TableBody>
                  {noteRow}
                  <TableRow>
                    <TableCell>Bank Account (IBAN):</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <CopyToClipboardCell content="39 1090 1056 0000 0001 5031 3938" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recipient's Name:</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <CopyToClipboardCell content="Dmytro Pastukh" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Amount:</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{"20 PLN"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </>
        ) : transferMethod === "REVOLUT" ? (
          <>
            <Typography variant="subtitle1">
              Send the deposit using the details:
            </Typography>
            {warning}
            <Card
              sx={{
                my: 2,
                borderColor: "#ddd",
                borderStyle: "solid",
                borderWidth: 1,
              }}
            >
              <Table sx-={{ my: 5 }}>
                <TableBody>
                  {noteRow}
                  <TableRow>
                    <TableCell>Revtag:</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <CopyToClipboardCell content="@dmytropastukh" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Amount:</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{"20 PLN"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </>
        ) : null}
        {!!transferMethod && (
          <Box sx={{ mb: 4 }}>
            <Controller
              name="transferSent"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} />}
                  label="The deposit has been transfered"
                />
              )}
            />

            <ErrorMessage
              errors={errors}
              name="transferSent"
              render={({ message }) => (
                <Typography color="error" variant="body2">
                  {message}
                </Typography>
              )}
            />
          </Box>
        )}

        <Box sx={{ mt: 2, mb: 4 }}>
          <Button variant="contained" type="submit">
            Send
          </Button>
        </Box>

        <DepositWarning />
      </form>
    </>
  );
};

const RadioOption = ({
  value,
  label,
  icon,
}: {
  value: string;
  label: string;
  icon?: ReactNode;
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          icon={icon}
          checkedIcon={icon}
          sx={{
            ".Mui-checked:has(&)": {
              // color: (theme) => theme.palette.primary.main,
              // color: "#fff",
            },
          }}
        />
      }
      label={
        <Box
          sx={{
            ".Mui-checked:has(&)": {
              // color: (theme) => theme.palette.primary.main,
              // color: "#fff",
            },
          }}
        >
          {label}
        </Box>
      }
      sx={{
        flex: 1,
        justifyContent: "center",
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "3px",
        m: 0,
        transition: "0.2s",
        "& svg": {
          transition: "0.2s",
        },
        "&:has(.Mui-checked)": {
          borderColor: (theme) => theme.palette.primary.main,
          background: (theme) => theme.palette.primary.main,
          color: "#fff",
          "& svg": {
            color: "#fff",
          },
        },
      }}
    />
  );
};
