import { Info } from "@mui/icons-material";
import { Alert, Typography } from "@mui/material";

export const VotingInfo = () => (
  <Alert variant="outlined" color="info" icon={<Info />} sx={{ my: 4 }}>
    <Typography variant="body2" sx={{ mb: 0.5 }}>
      Vote for the games before planning.
    </Typography>
    <Typography variant="caption" display="block" sx={{ mb: 1 }}>
      Otherwise you will be assigned randomly.
    </Typography>
    <Typography
      variant="caption"
      color="GrayText"
      display="block"
      sx={{ mb: 0 }}
    >
      Planning starts 3 days before the meetup.
    </Typography>
  </Alert>
);
