import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ReactNode } from "react";
import { Box } from "@mui/material";
import relativeTime from "dayjs/plugin/relativeTime";

// set the UTC time zone
dayjs.extend(utc);
dayjs.utc();

// set the Warsaw time zone
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Warsaw");

dayjs.extend(relativeTime);

export function formatDate(dateString: string | Date): string {
  const formattedDate = dayjs
    .utc(dateString)
    .format("dddd, MMMM D [at] HH:mm");
  return formattedDate;
}

export function isDateInFuture(dateString: string | Date): boolean {
  return dayjs(dateString).isAfter(Date.now());
}

export function formatDateNoWrap(dateString: string | Date): ReactNode {
  return (
    <Box whiteSpace="nowrap" display="inline">
      {formatDate(dateString)}
    </Box>
  );
}
