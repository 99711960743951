import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthTokenData } from "../../types/AuthTokenData";
import { RootState } from "../RootState";

export type AuthState = {
  /**
   * @remarks
   * AuthTokenData = logged in
   * null = no auth
   * undefined = is loading
   */
  tokenData: AuthTokenData | undefined | null;
};

const initialState: AuthState = { tokenData: undefined };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokenData(state, action: PayloadAction<AuthState["tokenData"]>) {
      state.tokenData = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export const authSelectors = {
  selectTokenData: (state: RootState) => state.auth.tokenData,
};
