import { Box, Button, List, ListItem, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { MeetupInfo } from "src/components/MeetupInfo";
import { VotingInfo } from "src/components/VotingInfo";
import { PageTitle } from "../../components/PageTitle";
import { formatDateNoWrap } from "../../helpers/formatDate";
import { useSubmit } from "../../hooks/useSubmit";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";

type Props = {
  application: Application;
  refetch: () => void;
};

export const ApplicationDepositedView = ({ application, refetch }: Props) => {
  const isVotingReady = application.meetup.games.length > 2;

  const { submit: onConfirm } = useSubmit({
    url: `/applications/${application.id}/vote-now`,
  });

  async function handleConfirm(e: React.MouseEvent) {
    e.preventDefault();
    await onConfirm();
    refetch();
  }

  useTitle("Spot Secured");
  return (
    <>
      <PageTitle title="Spot Secured" />

      <Box sx={{ mt: -2, mb: 4 }}>
        {application.meetup && <MeetupInfo meetup={application.meetup} showChat />}
      </Box>

      <p>
        Your spot has been <b>secured</b> with deposit.{" "}
        {!isVotingReady && <>The voting form will be prepared soon.</>}
      </p>

      {isVotingReady && (
        <>
          <VotingInfo />
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Vote for the games you want to play:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleConfirm}
          >
            Vote Now
          </Button>
        </>
      )}
    </>
  );
};
