import { GameModel, MeetupLevel } from "@pastukh-dm/wboard-games";

type GameGroup = "Main" | "Cooldown" | "Other";

type GameRange = { from?: number; to?: number };
type GameParams = { weight: GameRange; playtime: GameRange };
export const gameParamsByMeetupLevel: Record<
  MeetupLevel,
  { main: GameParams; cooldown: GameParams }
> = {
  [MeetupLevel.LIGHT]: {
    main: { weight: { from: 1.0, to: 2.6 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 2.6 }, playtime: { to: 60 } },
  },
  [MeetupLevel.MEDIUM]: {
    main: { weight: { from: 2.6, to: 3.4 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 3.4 }, playtime: { to: 60 } },
  },
  [MeetupLevel.HEAVY]: {
    main: { weight: { from: 3.4, to: 5 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 5 }, playtime: { to: 60 } },
  },
};

const inGameRange = (num: number | null, range: GameRange) => {
  if (!num) {
    return false;
  }
  const min = range.from || -Infinity;
  const max = range.to || Infinity;
  if (min <= num && num <= max) {
    return true;
  }
  return false;
};
const gameInGameRange = (game: GameModel, gameParams: GameParams) =>
  inGameRange(game.weight, gameParams.weight) &&
  inGameRange(game.maxPlaytime, gameParams.playtime);

const getGameGroup = (game: GameModel, level: MeetupLevel): GameGroup => {
  const gameParams = gameParamsByMeetupLevel[level];
  if (gameInGameRange(game, gameParams.main)) {
    return "Main";
  }
  if (gameInGameRange(game, gameParams.cooldown)) {
    return "Cooldown";
  }
  return "Other";
};

export const getReadableWeight = (weightRange: GameRange): string => {
  return `from ${(weightRange.from || 1).toFixed(2)} to ${(
    weightRange.to || 5
  ).toFixed(2)}`;
};

export const getReadablePlaytime = (weightRange: GameRange): string => {
  return `${(weightRange.from || 1).toFixed(2)}-${(weightRange.to || 5).toFixed(
    2
  )}`;
};
