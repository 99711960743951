import { Typography, Fade, Box, Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTitle } from "../hooks/useTitle";

import { useParams } from "react-router-dom";
import { NoMatchPage } from "./NoMatchPage";

export const SubscribePage = () => {
  useTitle("Unsubscribe");
  const { userId } = useParams();

  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );

  useEffect(() => {
    onMount();
  }, []);

  async function onMount() {
    setStatus("loading");
    try {
      await axios.post(`/users/${userId}/subscribe`);
      setStatus("success");
    } catch (e: any) {
      setError(
        e.response?.data?.error ||
          "Something went wrong. Please try again. If problem still occurs, please contact me https://www.facebook.com/pastukh.dm"
      );
      setStatus("error");
    }
  }

  if (!userId) {
    return <NoMatchPage />;
  }
  return (
    <>
      <Fade in={status === "loading"} mountOnEnter unmountOnExit exit={false}>
        <Typography variant="h3" mb={2}>
          Loading...
        </Typography>
      </Fade>

      <Fade
        in={status === "success"}
        timeout={1000}
        mountOnEnter
        unmountOnExit
        exit={false}
      >
        <Box>
          <Typography variant="h2" mb={2}>
            Subscribed
          </Typography>
          <Typography variant="h5">Glad to see you back!</Typography>
          <Typography variant="subtitle2">
            You will be receiving emails about upcoming meetups. Also if you
            already applied to some you will get emails regarding them as well.
          </Typography>
        </Box>
      </Fade>

      <Fade
        in={status === "error"}
        timeout={3000}
        mountOnEnter
        unmountOnExit
        exit={false}
      >
        <Alert severity="error">{error}</Alert>
      </Fade>
    </>
  );
};
