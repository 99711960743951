import { Box } from "@mui/material";
import { CustomAccordion } from "../components/CustomAccordion";
import { PageTitle } from "../components/PageTitle";

export const CodeOfConductPage = () => {
  return (
    <>
      <PageTitle title="Code of Conduct" />
      <CustomAccordion steps={CODE_OF_CONDUCT}  />
    </>
  );
};

export const CODE_OF_CONDUCT = [
  {
    id: "inclusivity",
    title: "Inclusivity",
    shortDescription:
      "We welcome everyone regardless of personal characteristics.",
    longDescription:
      "We believe that diversity is what makes our community vibrant and engaging. By embracing and celebrating differences, we can learn from each other and build a stronger community.",
  },
  {
    id: "positive-behavior",
    title: "Positive Behavior",
    shortDescription:
      "Treat others with kindness, respect, and sportsmanship. Help others.",
    longDescription:
      "We believe that playing games is a social activity, and we want everyone to have a fun and enjoyable experience. By treating others with kindness, respect, and sportsmanship, and helping others when needed, we can create a positive and inclusive environment.",
  },
  {
    id: "language",
    title: "Language",
    shortDescription: "Speak English to facilitate communication.",
    longDescription:
      "We understand that our participants come from different linguistic backgrounds, but to facilitate communication and inclusivity, we ask that all participants speak English during the event.",
  },
  {
    id: "fair-play",
    title: "Game Rules Adherence",
    shortDescription: "Learn and follow game rules. No cheating allowed.",
    longDescription:
      "We take the integrity of our games seriously, and we want everyone to have a fair and enjoyable experience. By learning and following the rules of the games being played, and refraining from cheating or unfair play, we can ensure that everyone has a good time.",
  },
  {
    id: "safety",
    title: "Safety and Comfort",
    shortDescription: "Prioritize safety and comfort.",
    longDescription:
      "We want everyone to feel safe and comfortable during our events, and we take measures to ensure that everyone's physical and emotional well-being is prioritized. We prohibit any threats, violence, or unsafe behavior, and we ask that participants refrain from using alcohol or drugs that may impair their judgment or behavior.",
  },
  {
    id: "consequences",
    title: "Consequences",
    shortDescription: "Violations may result in removal.",
    longDescription:
      "We want everyone to have a positive and enjoyable experience at our events, but we take violations of our code of conduct seriously. Violations of any of the above expectations may result in a warning or removal from the event, depending on the severity of the violation and at the discretion of the organizers.",
  },
];
