import { Typography, TypographyProps } from "@mui/material";

type Props = TypographyProps & {
  title: string;
};

export const PageTitle = ({ title, ...props }: Props) => {
  return (
    <Typography variant="h4" sx={{ mb: 4, ...props.sx }} {...props}>
      {title}
    </Typography>
  );
};
