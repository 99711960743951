import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { PageTitle } from "../../components/PageTitle";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { useSubmit } from "../../hooks/useSubmit";
import {
  GameVoteInterest,
  GameVoteExperience,
  CreateGameVote,
} from "../../types/GameVote";
import { GamesOnMeetupsModel } from "../../models/Game";
import {
  CooldownText,
  DislikeText,
  KnowText,
  LikeText,
  LoveText,
  MainGameText,
} from "src/components/typography";
import { VotingInfo } from "src/components/VotingInfo";
import { VotingGameList } from "src/components/VotingGameList";
import { CustomAccordion } from "src/components/CustomAccordion";
import { CustomCollapse } from "src/components/CustomCollapse";
import { VotingHelp } from "src/components/VotingHelp";
import { MeetupInfo } from "src/components/MeetupInfo";

type Props = {
  application: Application;
  refetch: () => void;
};
const votesSchema = yup.array(
  yup.object().shape({
    // applicationId: yup.string().required(),
    gameId: yup.string().required(),
    interest: yup
      .mixed<GameVoteInterest>()
      .oneOf(Object.values(GameVoteInterest))
      .required(),
    experience: yup
      .mixed<GameVoteExperience>()
      .oneOf(Object.values(GameVoteExperience))
      .required(),
  })
);

const createSchema = (minLikeVotes: number, maxDislikeVotes: number) => {
  const schema = yup.object().shape({
    mainVotes: votesSchema
      .test(
        "like-count",
        `At least ${minLikeVotes} MAIN GAMES should have LOVE/LIKE vote`,
        (votes) => {
          if (!votes) return false;

          const loveOrLikeCount = votes.filter(
            (vote) =>
              vote.interest === GameVoteInterest.LOVE ||
              vote.interest === GameVoteInterest.LIKE
          ).length;

          return loveOrLikeCount >= minLikeVotes;
        }
      )
      .test(
        "dislike-count",
        `Less than ${maxDislikeVotes} MAIN GAMES may have DISLIKE vote`,
        (votes, context) => {
          if (!votes) return false;

          const dislikeCount = votes.filter(
            (vote) => vote.interest === GameVoteInterest.DISLIKE
          ).length;
          return dislikeCount < maxDislikeVotes;
        }
      ),
    secondaryVotes: votesSchema,
  });
  return schema;
};

type FormData = yup.InferType<ReturnType<typeof createSchema>>;

export const ApplicationVotingView = ({ application, refetch }: Props) => {
  const { main, secondary } = application.meetup.games.reduce(
    (res, item) => {
      if (item.isMainGame) {
        res.main.push(item);
      } else {
        res.secondary.push(item);
      }
      return res;
    },
    {
      main: [] as GamesOnMeetupsModel[],
      secondary: [] as GamesOnMeetupsModel[],
    }
  );
  const minLikeVotes = Math.ceil(main.length * 0.25);
  const maxDislikeVotes = Math.floor(main.length * 0.5);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: "onChange",
    defaultValues: {
      mainVotes: main
        ?.sort((a, b) =>
          a.game.weight && b.game.weight && a.game.weight > b.game.weight
            ? -1
            : 1
        )
        .map((game) => ({
          gameId: game.gameId,
          interest: GameVoteInterest.NEUTRAL,
          experience: GameVoteExperience.NO,
        })),
      secondaryVotes: secondary
        ?.sort((a, b) =>
          a.game.weight && b.game.weight && a.game.weight > b.game.weight
            ? -1
            : 1
        )
        .map((game) => ({
          gameId: game.gameId,
          interest: GameVoteInterest.NEUTRAL,
          experience: GameVoteExperience.NO,
        })),
    },
    resolver: yupResolver(createSchema(minLikeVotes, maxDislikeVotes)),
  });

  const { submit } = useSubmit<{
    votes: CreateGameVote[];
  }>({
    url: `/applications/${application.id}/vote`,
  });

  const handleFormSubmit = async (data: FormData) => {
    await submit({
      votes: [...(data.mainVotes || []), ...(data.secondaryVotes || [])],
    });

    refetch();
  };
  const mainVotes = watch("mainVotes");

  const dislikeCount =
    mainVotes?.filter((vote) => vote.interest === GameVoteInterest.DISLIKE)
      ?.length || 0;
  const disableDislikeButton = dislikeCount >= maxDislikeVotes - 1;

  useTitle("Voting");
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <PageTitle title="Game Voting" />

        <Box sx={{ mt: -2, mb: 4 }}>
          {application.meetup && (
            <MeetupInfo meetup={application.meetup} showChat />
          )}
        </Box>

        {/* <VotingInfo /> */}
        <Typography
          variant="h5"
          mt={4}
          mb={2}
          sx={{
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 100,
            p: 1,
            boxShadow: "0 5px 5px -5px #0005",
          }}
        >
          Main Game
        </Typography>
        <Typography variant="body2" mb={4}>
          Based on your votes you will be assigned to one <MainGameText />.
        </Typography>

        <Box sx={{ my: 4 }}>
          <CustomCollapse
            title={`What does KNOW / LOVE / LIKE / DISLIKE mean?`}
          >
            <Box sx={{ p: 2 }}>
              <VotingHelp />
            </Box>
          </CustomCollapse>
        </Box>

        <VotingGameList
          fieldKey="mainVotes"
          games={main as any}
          control={control}
          disabled={disableDislikeButton}
        />

        <Typography
          variant="h5"
          mt={8}
          mb={2}
          sx={{
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 110,
            p: 1,
            boxShadow: "0 5px 5px -5px #0005",
          }}
        >
          Cooldown Games
        </Typography>
        <Typography variant="body2" mb={4}>
          Based on your votes these <CooldownText plural /> will be available.
        </Typography>

        <VotingGameList
          fieldKey="secondaryVotes"
          games={secondary as any}
          control={control}
          secondary
        />
        <Box my={4}>
          <ErrorMessage
            errors={errors}
            name="mainVotes"
            render={({ message }) => (
              <Typography color="error" variant="body2">
                {message}
              </Typography>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="secondaryVotes"
            render={({ message }) => (
              <Typography color="error" variant="body2">
                {message}
              </Typography>
            )}
          />
        </Box>
        <Box sx={{ my: 4 }}>
          <Button variant="contained" type="submit">
            Send votes
          </Button>
          {/* <pre>{JSON.stringify(all, null, "\t")}</pre> */}
        </Box>
      </form>
    </>
  );
};
