import { Application } from "./Application";

export type GameVote = {
  id: string;
  application: Application;
  applicationId: string;
  game: Game;
  gameId: string;
  interest: GameVoteInterest;
  experience: GameVoteExperience;
  createdAt: Date;
  updatedAt: Date;
};

export type CreateGameVote = Pick<
  GameVote,
  "experience" | "interest" | "gameId"
>;
export enum GameVoteInterest {
  LOVE = "LOVE",
  LIKE = "LIKE",
  NEUTRAL = "NEUTRAL",
  DISLIKE = "DISLIKE",
}
export enum GameVoteExperience {
  EXPERT = "EXPERT",
  YES = "YES",
  NO = "NO",
}

export type Game = {
  id: string;
  geekId: string;
};

export type GamesOnMeetupsWithGeekInfo = GamesOnMeetups & {
  geekInfo: GeekInfo;
};
export type GameWithGeekInfo = Game & {
  geekInfo: GeekInfo;
};

export type GeekInfo = {
  name: string;
  thumbnail: string;
  link: string;
  description: string;
  categories: string[];
  //   year: number;
  weight: number;
  rank: number;
  //   minplayers: 2,
  //   maxplayers: 4,
  //   maxplaytime: number;
};

export type GamesOnMeetups = {
  createdAt: Date;
  updatedAt: Date;
  gameId: string;
  meetupId: string;
  isMainGame: boolean;
};