import React from "react";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import { init as initFullStory } from "@fullstory/browser";

// initFacebookSDK();
export enum HttpStatusCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER = 500,
}
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;

// axios.interceptors.response.use(
//   (res) => res,
//   (error) => {
//     if (error.response.status === HttpStatusCodes.UNAUTHORIZED) {
//       window.location.href = ROUTES.AuthLogout;
//     }
//     return Promise.reject(error);
//   }
// );
axios.defaults.withCredentials = true;
// axios.interceptors.request.use((req) => {
//   req.withCredentials = true;
//   return req;
// });

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// root.render(<>Loading...</>);

// const checkFBInterval = setInterval(() => {
//   if (!!(window as any).FB) {
//     clearInterval(checkFBInterval);
//     root.render(
//       <React.StrictMode>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </React.StrictMode>
//     );
//   }
// }, 100);
try {
  initFullStory(
    { orgId: process.env.REACT_APP_FS_ID || "" },
    // ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
  );
} catch (e) {
  console.error(e);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
