import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HomeHeader } from "./HomeHeader";

export const Layout = () => {
  return (
    <Box>
      <HomeHeader />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box sx={{ my: 3, maxWidth: 600, mx: "auto" }}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
};
