import { Typography, Fade, Box, Link, Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTitle } from "../hooks/useTitle";

import { Link as RouterLink, useParams } from "react-router-dom";
import { NoMatchPage } from "./NoMatchPage";
import { ROUTES } from "../consts/routes";

export const UnsubscribePage = () => {
  useTitle("Unsubscribe");
  const { userId } = useParams();

  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );

  useEffect(() => {
    onMount();
  }, []);

  async function onMount() {
    setStatus("loading");
    try {
      await axios.post(`/users/${userId}/unsubscribe`);
      setStatus("success");
    } catch (e: any) {
      setError(
        e.response?.data?.error ||
          "Something went wrong. Please try again. If problem still occurs, please contact me https://www.facebook.com/pastukh.dm"
      );
      setStatus("error");
    }
  }

  if (!userId) {
    return <NoMatchPage />;
  }
  return (
    <>
      <Fade in={status === "loading"} mountOnEnter unmountOnExit exit={false}>
        <Typography variant="h3" mb={2}>
          Loading...
        </Typography>
      </Fade>

      <Fade
        in={status === "success"}
        timeout={3000}
        mountOnEnter
        unmountOnExit
        exit={false}
      >
        <Box>
          <Typography variant="h2" mb={2}>
            Unsubscribed
          </Typography>
          <Typography variant="h5">We'd be happy to have you back!</Typography>
          <Typography variant="subtitle2">
            If you change your mind, you can{" "}
            <Link
              component={RouterLink}
              to={ROUTES.Subscribe.replace(":userId", userId)}
            >
              re-subscribe
            </Link>{" "}
            or{" "}
            <Link component={RouterLink} to={ROUTES.Apply}>
              apply
            </Link>{" "}
            to one of our meetups
          </Typography>
        </Box>
      </Fade>

      <Fade
        in={status === "error"}
        timeout={3000}
        mountOnEnter
        unmountOnExit
        exit={false}
      >
        <Alert severity="error">{error}</Alert>
      </Fade>
    </>
  );
};
