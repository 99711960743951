import { Grid, Box, Typography, useTheme } from "@mui/material";
import { Card, CardActionArea, CardContent } from "@mui/material";

export const HomeItem = ({
  href,
  icon,
  text,
  description,
  iconColor,
  isTextAlwaysVisible,
  highlighted,
}: HomeItemProps) => {
  const theme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        // borderColor: iconColor,
        borderColor: highlighted ? "#1877F2" : undefined,
        borderWidth: highlighted ? 2 : 1,
        // 'border-image': highlighted && 'linear-gradient(45deg, #e2d826, #28e3e0, #e46926) 1'
        // 'border-image': highlighted && 'linear-gradient(45deg, #e2d826, #e2d826, #e2d826) 1'
      }}
    >
      <CardActionArea target="_blank" rel="noreferrer" href={href}>
        <CardContent
          sx={{
            [theme.breakpoints.down("md")]: {
              p: 1,
            },
          }}
        >
          <Grid container alignItems="center" wrap="nowrap" gap={2} spacing={0}>
            <Grid
              sx={{
                fontSize: "40px",
                py: 0,
                [theme.breakpoints.down("md")]: {
                  fontSize: "25px",
                },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={iconColor}
              >
                {icon}
              </Box>
            </Grid>
            <Grid
              sx={{
                py: 0,
                display: isTextAlwaysVisible
                  ? "block"
                  : { xs: "none", sm: "block" },
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                {text}
              </Typography>
              {/* <Typography variant="body2">{description}</Typography> */}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export type HomeItemProps = {
  href: string;
  icon: JSX.Element;
  iconColor?: string;
  highlighted?: true;
  text: string;
  description?: string;
  isTextAlwaysVisible?: true;
};
