import { FileCopyOutlined } from "@mui/icons-material";
import {
  Stack,
  Box,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";

interface CopyProps {
  content: string;
}

export const CopyToClipboardCell: React.FC<CopyProps> = ({ content }) => {
  const [copiedContent, setCopiedContent] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopiedContent(content);
    setShowSnackbar(true);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // prevent click from propagating to the table cell
    handleCopy();
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
        {content}
      </Box>
      <Tooltip title="Copy to clipboard" placement="top">
        <IconButton onClick={handleClick}>
          <FileCopyOutlined />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Copied "<b>{copiedContent}</b>" to clipboard!
        </Alert>
      </Snackbar>
    </Stack>
  );
};
