import { School, Favorite, ThumbUp, ThumbDown } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const MainGameText = ({ plural }: { plural?: true }) => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="primary"
  >
    MAIN GAME{plural && "S"}
  </Typography>
);
export const CooldownText = ({ plural }: { plural?: true }) => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="primary"
  >
    COOLDOWN GAME{plural && "S"}
  </Typography>
);
export const KnowText = () => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="#81C784"
    // color="error.main"
    color="info.main"
  >
    <School
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        mt: "0px",
        fontSize: "14px",
      }}
    />{" "}
    KNOW
  </Typography>
);
export const LoveText = () => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="#81C784"
    // color="error.main"
    color="secondary.main"
  >
    <Favorite
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        mt: "0px",
        fontSize: "14px",
      }}
    />{" "}
    LOVE
  </Typography>
);
export const LikeText = () => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="#81C784"
    // color="error.main"
    color="success.main"
  >
    <ThumbUp
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        mt: "0px",
        fontSize: "14px",
      }}
    />{" "}
    LIKE
  </Typography>
);
export const DislikeText = () => (
  <Typography
    display="inline"
    noWrap
    variant="body2"
    fontWeight={700}
    // color="#E57373"
    color="error.main"
  >
    <ThumbDown
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        mb: "0px",
        fontSize: "14px",
      }}
    />{" "}
    DISLIKE
  </Typography>
);
