import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  styled,
  Typography,
} from "@mui/material";
import { Link, Link as RouterLink } from "react-router-dom";
import { CustomAccordion } from "src/components/CustomAccordion";
import { CooldownText, MainGameText } from "src/components/typography";

import { HomeCommunity } from "../components";
import { HomeSocial } from "../components/HomeSocial";
import { ROUTES } from "../consts/routes";
import { APPLYING_AND_PARTICIPATING } from "./ApplyingAndParticipatingPage";

export const HomePage = () => {
  const interestedToJoin = (
    <StyledSection sx={{ textAlign: "center" }}>
      <Typography variant="h6" mb={1}>
        Interested to join a meetup?
      </Typography>
      <Button
        variant="contained"
        size="large"
        component={RouterLink}
        to={ROUTES.Apply}
      >
        Apply here
      </Button>
    </StyledSection>
  );
  return (
    <Container maxWidth="md" sx={{ py: 0 }}>
      {interestedToJoin}
      <StyledSection>
        <Typography variant="h5" mb={1}>
          About meetups
        </Typography>
        <Typography variant="body1" mb={2}>
          We organize well-planned board game meetups of different difficulty
          levels.
        </Typography>
      </StyledSection>

      <StyledSection>
        <Typography variant="h6" mb={1}>
          What to expect?
        </Typography>
        <Typography variant="body1" mb={2}>
          Usually a meetup lasts around 6-8 hours. Every meetup starts with one{" "}
          <MainGameText /> and continues with many <CooldownText plural />.
        </Typography>
        <List>
          <ListItem>
            <Box>
              <Typography variant="body2">
                <MainGameText /> – longer and more difficult game
              </Typography>
              <Typography variant="caption" color="GrayText">
                The difficulty depends on meetup level and duration is not less
                than 2 hours
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography variant="body2">
                <CooldownText plural /> – shorter and easier games
              </Typography>
              <Typography variant="caption" color="GrayText">
                Games can have any difficulty and duration, but usually less
                than a <MainGameText />
              </Typography>
            </Box>
          </ListItem>
        </List>
      </StyledSection>

      <StyledSection>
        <Typography variant="h6" mb={1}>
          What meetup levels are there?
        </Typography>
        <List>
          <ListItem>
            <Box>
              <Typography variant="body2">
                <b>Light</b> – a meetup with easier games, the best for new
                players and ones who never played board games before
              </Typography>
              <Typography variant="caption" color="GrayText">
                Weight: 1.00-2.60
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography variant="body2">
                <b>Medium</b> – a meetup with games of medium difficulty, the
                best for players with some board game experience
              </Typography>
              <Typography variant="caption" color="GrayText">
                Weight: 2.60-3.40
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography variant="body2">
                <b>Heavy</b> – a meetup with more difficult games, the best for
                for experienced players who seek for a challenge
              </Typography>
              <Typography variant="caption" color="GrayText">
                Weight: 3.40-5.00
              </Typography>
            </Box>
          </ListItem>
        </List>
      </StyledSection>

      <StyledSection>
        <Typography variant="h6" mb={1}>
          Is experience with games required?
        </Typography>
        <Typography variant="body2" mb={1}>
          You don't have to have any previous experience to play on a meetup of
          any level.
        </Typography>
        <Typography variant="body2" mb={1}>
          But it is HIGHLY recommended to learn how to play your{" "}
          <MainGameText /> to make experience for all players smoother.
        </Typography>
        <Typography variant="caption" color="GrayText">
          When you are assigned to a <MainGameText /> you will receive a link
          with how-to-play videos
        </Typography>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" mb={1}>
          How to join a meetup?
        </Typography>

        <CustomAccordion steps={APPLYING_AND_PARTICIPATING} />
      </StyledSection>
      {interestedToJoin}

      {/* <CustomAccordion
        steps={[
          {
            id: "join",
            title: "How to join a meetup?",
            longDescription: "fgd",
          },
        ]}
      /> */}
      <Contacts />
    </Container>
  );
};

const StyledSection = styled(Box)({
  marginBottom: "40px",
});

export const Contacts = () => (
  <>
    <StyledSection>
      <Box sx={{ flex: "1", display: "flex" }}>
        <Box sx={{ margin: "auto" }}>
          <HomeCommunity />
        </Box>
      </Box>
    </StyledSection>

    <StyledSection>
      <Box sx={{ flex: "0" }}>
        <HomeSocial />
      </Box>
    </StyledSection>
  </>
);

// 1.00
// -- Light
// 1.80
// -- Medium Light
// 2.60
// -- Medium
// 3.40
// -- Medium Heavy
// 4.20
// -- Heavy
// 5.00

// 1.00-2.60 Light
// 2.60-3.40 Medium
// 3.40-5.00 Heavy
