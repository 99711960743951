import { Warning } from "@mui/icons-material";
import { Alert, Typography } from "@mui/material";

export const DepositWarning = () => (
  <Alert variant="outlined" color="warning" icon={<Warning />} sx={{ my: 4 }}>
    <Typography variant="body2" sx={{ mb: 0.5 }}>
      You have <b>24 hours</b> to confirm and send a <b>20 PLN</b> deposit.
    </Typography>
    <Typography variant="caption" display="block" sx={{ mb: 0 }}>
      Otherwise your application will be automatically canceled
    </Typography>
  </Alert>
);
