import { Fade, Box, Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { PageTitle } from "../../components/PageTitle";
import { useTitle } from "../../hooks/useTitle";
import { Meetup } from "../../types/Meetup";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useFetch } from "../../hooks/useFetch";
import { AppLoading } from "src/components";
import { ApplyStepSuccess } from "./ApplyStepSuccess";
import { ApplyStepNoMeetups } from "./ApplyStepNoMeetups";
import { ApplyForm } from "./ApplyForm";
import { isDateInFuture } from "src/helpers/formatDate";

const schema = yup
  .object({
    meetup: yup.string().required(),
    email: yup.string().email().required("required"),
  })
  .required();

export type PreApplyFormData = yup.InferType<typeof schema>;

export const ApplyPage = () => {
  useTitle("Apply");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PreApplyFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    data,
    isLoading,
    error: fetchError,
  } = useFetch<{ meetups: Meetup[]; prev: Meetup }>({ url: "/meetups/open" });

  const meetups = data?.meetups;

  const availableMeetups = useMemo(
    () =>
      meetups
        ?.filter((item) => isDateInFuture(item.applyTo))
        .sort((a, b) => (a.startAt > b.startAt ? 1 : -1)),
    [meetups]
  );
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>();

  const onSubmit: SubmitHandler<PreApplyFormData> = async (data) => {
    setError(undefined);
    setSuccess(undefined);
    try {
      const payload = {
        email: data.email,
        meetup: data.meetup,
      };
      await axios.post<{ application: { id: string } }>(
        `/applications/preApply`,
        payload
      );
      setSuccess(true);
    } catch (e: any) {
      setError(
        e?.response?.data?.error ||
          "Something went wrong. Please try again. If problem still occurs, please contact me https://www.facebook.com/pastukh.dm"
      );
    } 
  };
  const watchAll = watch();

  useEffect(() => {
    if (!watchAll.meetup && meetups?.length === 1) {
      setValue("meetup", meetups[0].id);
    }
  }, [meetups, watchAll.meetup]);

  const anyLoading = isLoading;
  const anyError = fetchError;

  return (
    <>
      <AppLoading open={anyLoading} />

      <Fade in={!!success} timeout={3000} mountOnEnter unmountOnExit>
        <div>
          <ApplyStepSuccess email={watchAll.email} />
        </div>
      </Fade>
      <Fade in={!success} exit={false} mountOnEnter unmountOnExit>
        <div>
          <Box>
            <PageTitle title="Apply" />

            {anyError ? (
              <Alert severity="error">
                Something went wrong. Please try again. If problem still occurs,
                please contact me https://www.facebook.com/pastukh.dm
              </Alert>
            ) : isLoading ? (
              "Loading..."
            ) : availableMeetups && availableMeetups.length === 0 ? (
              <>
                <ApplyStepNoMeetups previousMeetup={data?.prev} />
              </>
            ) : availableMeetups && availableMeetups.length > 0 ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ApplyForm
                  control={control}
                  watch={watch}
                  errors={errors}
                  availableMeetups={availableMeetups}
                  isLoading={anyLoading}
                  onSubmit={handleSubmit(onSubmit)}
                />

                <Fade in={!!error} mountOnEnter unmountOnExit>
                  <Box sx={{ mt: 4 }}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                </Fade>
              </form>
            ) : (
              <Alert severity="error">
                Something went wrong. Please try again. If problem still occurs,
                please contact me https://www.facebook.com/pastukh.dm
              </Alert>
            )}
          </Box>
        </div>
      </Fade>
    </>
  );
};
