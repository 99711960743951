import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

type PayloadType = { [key: string]: any };

type SubmitHookReturnType<T> = {
  request: (
    payload?: PayloadType,
    config?: UseSubmitProps
  ) => Promise<T | undefined>;
  isLoading: boolean;
  data?: T;
  error?: string;
  isSuccess?: boolean;
  withCredentials?: boolean;
};

type UseSubmitProps = AxiosRequestConfig;

export const useRequest = <T>(
  props?: UseSubmitProps
): SubmitHookReturnType<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>();

  const request = async (
    payload?: PayloadType,
    config: UseSubmitProps = {}
  ) => {
    setIsLoading(true);
    setError(undefined);
    setIsSuccess(undefined);

    try {
      const { url, method, withCredentials, ...rest } =
        { ...props, ...config } || {};
      const data = method !== "GET" ? payload : undefined;
      const params = method === "GET" ? payload : undefined;
      const response = await axios({
        url: url || "/api/submit",
        method: method || "POST",
        data,
        params,
        withCredentials,
        ...rest,
      });
      const responseData: T = response.data;

      setData(responseData);
      setIsSuccess(true);
      return responseData;
    } catch (error: any) {
      console.error(error);
      setError(error.message || "An unknown error occurred.");
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { request, isLoading, data, error, isSuccess };
};
