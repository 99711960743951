import { Box, Grid, Typography } from "@mui/material";
import { FaLock, FaFacebookSquare } from "react-icons/fa";
import { HomeItem, HomeItemProps } from "./HomeItem";
export const HomeCommunity = () => {
  return (
    <Grid container columnGap={2} sx={{ textAlign: "center" }}>
      <Grid xs={12}>
        <Typography variant="h5" sx={{ mb: 0 }}>
          Enthusiasts Community
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0 }}>
          Information about events, board games discussions.
        </Typography> */}
      </Grid>
      {LINKS_1.map((itemProps) => (
        <Grid
          key={itemProps.href}
          justifySelf="center"
          sx={{ mx: "auto", my: 2, textAlign: "left" }}
        >
          <HomeItem {...itemProps} isTextAlwaysVisible />
        </Grid>
      ))}
      {/* <Grid xs={12} sx={{}}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <b>Join if you want to be part of our community</b>
        </Typography>
      </Grid> */}
    </Grid>
  );
};

const LINKS_1: HomeItemProps[] = [
  {
    href: "https://www.facebook.com/groups/wboard.games",
    icon: (
      <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            transform: "scale(50%) translate(50%, 50%)",
            color: "#5f6771",
            filter:
              "drop-shadow(4px 4px 0 #fffa) drop-shadow(-4px -4px 0 #fffa)",
            transformOrigin: "100% 100%",
          }}
        >
          <FaLock />
        </Box>
        <FaFacebookSquare />
      </Box>
    ),
    text: "Warsaw Board Games Enthusiasts",
    highlighted: true,
    description: "Private Facebook group",
    iconColor: "#1877F2",
  },
];
