import { Box } from "@mui/material";
import { PageTitle } from "../components/PageTitle";

export const TermsAndConditionsPage = () => {
  return (
    <>
      <PageTitle title="Terms and Conditions" />
      <Box
        component="ol"
        sx={{
          p: 0,
          m: 0,
          "& > li": {
            margin: `0 0 10px`,
            // fontSize: `14px`,

            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: "400",
            fontSize: "0.875rem",
            lineHeight: "1.43",
            letterSpacing: "0.01071em",
          },
        }}
      >
        <li>
          <b>Information Collection</b>: We collect your personal information,
          including but not limited to email, name, social media links, full
          name, phone number, game preferences, and feedback, for organizing
          board game meetups and communicating event details.
        </li>
        <li>
          <b>Privacy</b>: Your personal information is only used for the stated
          purposes and won't be shared with third parties without your consent.
        </li>
        <li>
          <b>Responsibilities</b>: By providing your personal information, you
          agree to learn the assigned game rules, arrive on time, give feedback,
          and follow our code of conduct.
        </li>
        <li>
          <b>Deposits</b>: A deposit is required to secure your spot, returnable
          or donatable to support future events. Deposit details will be
          emailed.
        </li>
        <li>
          <b>Liability</b>: We are not responsible for any loss, injury, or
          damage occurring during the event. Participation is at your own risk.
        </li>
        <li>
          <b>Modifications</b>: We reserve the right to modify these terms at
          any time and will notify you of changes via email.
        </li>
        <li>
          <b>Communication</b>: Your email will be used to share event
          information such as date, time, location, and games, as well as
          reminders and updates.
        </li>
        <li>
          <b>Opt-out</b>: Unsubscribe at any time by clicking the "unsubscribe"
          link in the email or contacting us directly.
        </li>
        <li>
          <b>Code of Conduct</b>: All participants must follow our code of
          conduct, which includes respectful behavior and game rule adherence.
          Violators may be removed.
        </li>
        <li>
          <b>Media</b>: Event photos or videos may be taken and used on our
          website, social media, or promotional materials. Participation grants
          permission for this usage.
        </li>
        <li>
          <b>Minors</b>: Participants under 18 need parental/guardian consent.
          Parents/guardians are responsible for the minor's behavior.
        </li>
        <li>
          <b>Returns</b>: Notify us ASAP if you must cancel. Your deposit may be
          returned if you cancel before a game is assigned to you. The return
          depends on the timing of your cancellation and our ability to fill
          your spot.
        </li>
      </Box>
    </>
  );
};
