import {
  Alert,
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconContainerProps,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PageTitle } from "../../components/PageTitle";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSubmit } from "../../hooks/useSubmit";
import { CreateGameVote } from "../../types/GameVote";
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { ErrorMessage } from "@hookform/error-message";
import { RefObject, useRef, useState } from "react";
import { useEffect } from "react";
import { MeetupInfo } from "src/components/MeetupInfo";

type Props = {
  application: Application;
  refetch: () => void;
};

enum DepositAction {
  DONATE = "DONATE",
  RETURN = "RETURN",
}

const schema = yup.object().shape({
  rating: yup.number().min(1).max(5).required(),
  comments: yup.string().nullable(),
  depositAction: yup.mixed().oneOf(Object.values(DepositAction)).required(),
  subscribe: yup.boolean().required(),
});

type FormData = yup.InferType<typeof schema>;

export const ApplicationFeedbackRequestedView = ({
  application,
  refetch,
}: Props) => {
  const [commented, setCommented] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<FormData>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { submit, isSuccess, error, isLoading } = useSubmit({
    url: `/applications/${application.id}/feedback`,
  });
  const handleFormSubmit = async (data: FormData) => {
    await submit({
      rating: data.rating,
      comments: data.comments,
      depositAction: data.depositAction,
      subscribe: data.subscribe,
    });
  };

  const all = watch();
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [all.rating]);

  useTitle("Feedback");
  return (
    <>
      <Fade in={!!isSuccess} timeout={3000} mountOnEnter unmountOnExit>
        <Box>
          <Typography variant="h2" mb={2}>
            Thank you!
          </Typography>
          <Typography variant="body1">
            Your feedback is more valuable than a rare, out-of-print board game.
            <br />
            It will be used it to level up our next meetup!
          </Typography>
        </Box>
      </Fade>

      <Fade in={!isSuccess} exit={false} mountOnEnter unmountOnExit>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <PageTitle title="Feedback" />

          <Box sx={{ mt: -2, mb: 4 }}>
            {application.meetup && <MeetupInfo meetup={application.meetup} showChat />}
          </Box>

          <Stack gap={3}>
            <Typography variant="subtitle1" gutterBottom>
              Your feedback is the missing tile to complete our perfect board
              game meetup!
            </Typography>

            <FormControl>
              <Typography variant="subtitle2" gutterBottom>
                Rate your experience
              </Typography>
              <Controller
                control={control}
                name="rating"
                render={({ field }) => (
                  <>
                    <RadioGroup value={field} row>
                      {Object.entries(customIcons).map(([value, item]) => (
                        <>
                          <FormControlLabel
                            value={value}
                            onChange={() => {
                              field.onChange(+value);
                            }}
                            control={
                              <CustomRadio
                                value={value}
                                checked={field.value === +value}
                              />
                            }
                            label={item.label}
                            disabled={isLoading}
                            sx={{
                              m: -1,
                              "&	.MuiFormControlLabel-label": {
                                display: "none",
                              },
                              "&	svg": {
                                fontSize: "80px",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "15vw",
                                },
                              },
                            }}
                          />
                        </>
                      ))}
                    </RadioGroup>
                  </>
                )}
              />
            </FormControl>

            {all.rating && (
              <>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    {all.rating == 5
                      ? "What made this meetup exceptional for you?"
                      : all.rating == 4
                      ? "What was your favorite part of the meetup?"
                      : all.rating == 3
                      ? "What did you like and dislike about the meetup?"
                      : all.rating == 2
                      ? "What can be improved for the next time?"
                      : all.rating == 1
                      ? "What was the issue you experienced at the meetup?"
                      : null}
                  </Typography>
                  <TextField
                    disabled={isLoading}
                    {...register("comments")}
                    inputRef={inputRef}
                    // disabled={isSubmitting}
                    helperText={
                      <ErrorMessage errors={errors} name="comments" />
                    }
                    error={!!errors.comments}
                    fullWidth
                  />
                </Box>
                {!commented && (
                  <Box sx={{ my: 0 }}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        setCommented(true);
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                )}
              </>
            )}
            {commented && (
              <Box>
                <FormControl>
                  <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    What to do with your deposit?
                  </Typography>
                  <RadioGroup>
                    <FormControlLabel
                      value={DepositAction.DONATE}
                      label="Donate to support meetups"
                      disabled={isLoading}
                      control={<Radio size="small" />}
                      componentsProps={{
                        typography: {
                          variant: "subtitle2",
                          sx: { fontWeight: 400 },
                        },
                      }}
                      {...register("depositAction")}
                    />
                    <FormControlLabel
                      value={DepositAction.RETURN}
                      label="Return the deposit back"
                      disabled={isLoading}
                      control={<Radio size="small" />}
                      componentsProps={{
                        typography: {
                          variant: "subtitle2",
                          sx: { fontWeight: 400 },
                        },
                      }}
                      {...register("depositAction")}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            {all.depositAction && (
              <Box>
                <FormControl>
                  <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    Do you want to be notified about future meetups?
                  </Typography>
                  <RadioGroup>
                    <FormControlLabel
                      value={true}
                      label="Yes"
                      disabled={isLoading}
                      control={<Radio size="small" />}
                      componentsProps={{
                        typography: {
                          variant: "subtitle2",
                          sx: { fontWeight: 400 },
                        },
                      }}
                      {...register("subscribe")}
                    />
                    <FormControlLabel
                      value={false}
                      label="No"
                      disabled={isLoading}
                      control={<Radio size="small" />}
                      componentsProps={{
                        typography: {
                          variant: "subtitle2",
                          sx: { fontWeight: 400 },
                        },
                      }}
                      {...register("subscribe")}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Stack>
          {all.rating && all.depositAction && all.subscribe && (
            <Box sx={{ my: 4 }}>
              <Button variant="contained" type="submit" disabled={isLoading}>
                Send
              </Button>
            </Box>
          )}
          {!!error && <Alert severity="error">{error}</Alert>}
        </form>
      </Fade>
    </>
  );
};

const customIcons: {
  [index: string]: {
    icon: (color: string) => React.ReactElement;
    label: string;
    color: "secondary" | "error" | "warning" | "primary" | "success";
  };
} = {
  1: {
    icon: (color) => <SentimentVeryDissatisfied color={color as any} />,
    color: "secondary",
    label: "Very Poor",
  },
  2: {
    icon: (color) => <SentimentDissatisfied color={color as any} />,
    color: "error",
    label: "Poor",
  },
  3: {
    icon: (color) => <SentimentSatisfied color={color as any} />,
    color: "warning",
    label: "Fair",
  },
  4: {
    icon: (color) => <SentimentSatisfiedAlt color={color as any} />,
    color: "primary",
    label: "Good",
  },
  5: {
    icon: (color) => <SentimentVerySatisfied color={color as any} />,
    color: "success",
    label: "Excellent",
  },
};

function CustomRadio(props: any) {
  const { value, checked, ...rest } = props;
  return (
    <Radio
      {...rest}
      icon={customIcons[value].icon(checked ? customIcons[value].color : "")}
    />
  );
}
