import { Warning } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";

export const ApplyStepSuccess = ({ email }: { email: string }) => {
  return (
    <Box>
      <Typography variant="h2" mb={2}>
        Verify your email
      </Typography>
      <Alert color="warning" icon={<Warning />} sx={{ my: 2 }}>
        <AlertTitle sx={{ mb: 0 }}>
          Your application is not finished.
        </AlertTitle>
      </Alert>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Verification link was sent to <b>{email}</b>
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        If you don't see it in your inbox, check your spam folder.
      </Typography>
      <Typography variant="body2">
        Still don't see it? Contact me{" "}
        <a href="https://fb.com/pastukh.dm" target="_blank">
          fb.com/pastukh.dm
        </a>
      </Typography>
    </Box>
  );
};
