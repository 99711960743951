export const SLUG = ":id";

export const ROUTES = {
  Home: `/`,

  Apply: `/apply`,
  TermsAndConditions: `/conditions`,
  Code: `/code`,
  Participating: `/participating`,
  Application: `/application/:applicationId`,

  Unsubscribe: `/unsubscribe/:userId`,
  Subscribe: `/subscribe/:userId`,
};
