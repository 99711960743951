import { Stack, Typography, Button, Box } from "@mui/material";
import axios from "axios";
import React from "react";
import { PageTitle } from "../../components/PageTitle";
import { useSubmit } from "../../hooks/useSubmit";
import { useTitle } from "../../hooks/useTitle";
import { Application } from "../../types/Application";
import { AddedGamesPreview } from "../../components/AddedGamesPreview";
import { DepositWarning } from "src/components/DepositWarning";
import { MeetupInfo } from "src/components/MeetupInfo";

type Props = {
  application: Application;
  refetch: () => void;
};

export const ApplicationAcceptedView = ({ application, refetch }: Props) => {
  const { submit: onConfirm } = useSubmit({
    url: `/applications/${application.id}/confirm`,
  });
  const { submit: onCancel } = useSubmit({
    url: `/applications/${application.id}/cancel`,
  });

  async function handleConfirm(e: React.MouseEvent) {
    e.preventDefault();
    await onConfirm();
    refetch();
  }
  async function handleCancel(e: React.MouseEvent) {
    e.preventDefault();
    await onCancel();
    refetch();
  }

  useTitle("Confirm Your Application");
  return (
    <>
      <PageTitle title="Confirm Your Application" />

      <Box sx={{ mt: -2, mb: 4 }}>
        {application.meetup && <MeetupInfo meetup={application.meetup} />}
      </Box>

      <>
        <Stack mb={4} gap={2}>
          <Typography variant="subtitle2" fontSize={16}>
            Congratulations!
          </Typography>
          <Typography variant="body1">
            Your application to participate in <b>{application.meetup.name}</b>{" "}
            event has been accepted.
          </Typography>
          <Typography variant="subtitle2">
            To proceed with your application, please confirm or cancel using the
            buttons below:
          </Typography>
          <Stack direction="row" mt={1} gap={3} justifyContent="left">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="large"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Stack>
          <DepositWarning />
          {/* <Typography variant="h5">Games</Typography> */}
          {/* <AddedGamesPreview
            games={application.meetup.games.filter((game) => game.isMainGame)}
          /> */}
        </Stack>
      </>
    </>
  );
};
