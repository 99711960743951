import { Backdrop, CircularProgress } from "@mui/material";
import { colorByLevel } from "src/utils/colors";

export const AppLoading = ({ open }: { open: boolean }) => {
  return (
    <Backdrop
      open={open}
      sx={{
        background: "#fffb",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress
        size="20vmin"
        sx={{
          color: colorByLevel.LIGHT,
          animation: "color-shift 4s ease infinite",
          "@keyframes color-shift": {
            "0%": {
              color: colorByLevel.LIGHT,
            },
            "33%": {
              color: colorByLevel.MEDIUM,
            },
            "66%": {
              color: colorByLevel.HEAVY,
            },
            "100%": {
              color: colorByLevel.LIGHT,
            },
          },
        }}
      />
    </Backdrop>
  );
};
