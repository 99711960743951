import { ExpandMore } from "@mui/icons-material";
import {
  Typography,
  List,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export const AddedGamesPreview = ({
  title,
  weight,
  games,
}: {
  games?: { game: { name: string | null; link: string } }[];
  title?: string;
  weight?: string;
}) => {
  const theme = useTheme();
  return (
    <>
      {!!games && games.length > 0 ? (
        <>
          {/* <Typography variant="body2">Games in the voting list:</Typography> */}

          <Accordion
            square
            sx={{
              boxShadow: "none",
              border: `1px solid ${grey[200]}, background: grey[50]`,
              m: 0,
              p: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                m: 0,
                p: 0,
                minHeight: 0,
                justifyContent: "flex-start",
                color: theme.palette.primary.main,
                "&.Mui-expanded": { m: 0, p: 0, minHeight: 0 },
                "&.Mui-expanded .MuiAccordionSummary-content": {
                  m: 0,
                  p: 0,
                },
                "& .MuiAccordionSummary-content": {
                  m: 0,
                  p: 0,
                  flexGrow: "initial",
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  color: "inherit",
                },
              }}
            >
              <Typography variant="body2">
                {title || "Games"} ({games.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ m: 0, p: 0 }}>
              <List>
                {games.map((i) => (
                  <ListItem sx={{ py: 0, m: 0 }}>
                    <Typography
                      variant="caption"
                      component={"a"}
                      href={i.game.link}
                      target="_blank"
                    >
                      {i.game.name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      ) : null}
    </>
  );
};
