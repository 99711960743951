import {
  Box,
  Button,
} from "@mui/material";
import { useMemo } from "react";
import {
  Control,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { AddedGamesPreview } from "src/components/AddedGamesPreview";
import { FormField } from "src/components/form/FormField";
import {
  gameParamsByMeetupLevel,
  getReadableWeight,
} from "src/helpers/gameRange";
import { Meetup } from "src/types/Meetup";
import { ApplyMeetupInfo } from "./ApplyMeetupInfo";
import { PreApplyFormData } from "./ApplyPage";
import { SelectMeetup } from "./SelectMeetup";

export const ApplyForm = ({
  control,
  watch,
  availableMeetups,
  errors,
  isLoading,
  onSubmit,
}: {
  control: Control<PreApplyFormData>;
  watch: UseFormWatch<PreApplyFormData>;
  errors: FieldErrors<PreApplyFormData>;
  availableMeetups: Meetup[];
  isLoading: boolean;
  onSubmit: () => void;
}) => {
  const watchAll = watch();

  const selectedMeetup = useMemo(() => {
    return availableMeetups?.find((i) => i.id === watchAll.meetup);
  }, [availableMeetups, watchAll.meetup]);

  const gameParams =
    selectedMeetup?.level && gameParamsByMeetupLevel[selectedMeetup?.level];
  const mainGames = selectedMeetup?.games.filter((game) => !!game.isMainGame);
  const cooldownGames = selectedMeetup?.games.filter(
    (game) => !game.isMainGame
  );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <SelectMeetup control={control} availableMeetups={availableMeetups} />
      </Box>
      {selectedMeetup && (
        <Box sx={{ mb: 4 }}>
          <ApplyMeetupInfo meetup={selectedMeetup} />

          {gameParams && (
            <Box component="ul" sx={{ mt: 1, mb: 3 }}>
              {mainGames && mainGames.length > 0 && (
                <Box component="li" sx={{ mb: 0 }}>
                  <AddedGamesPreview
                    title={`Main games`}
                    weight={getReadableWeight(gameParams?.main.weight)}
                    games={mainGames}
                  />
                </Box>
              )}

              {cooldownGames && cooldownGames.length > 0 && (
                <Box component="li" sx={{ mb: 0 }}>
                  <AddedGamesPreview
                    title={`Cooldown games`}
                    weight={getReadableWeight(gameParams?.cooldown.weight)}
                    games={cooldownGames}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      {!!watchAll.meetup && (
        <Box sx={{ mb: 4 }}>
          <FormField
            control={control}
            errors={errors}
            name="email"
            label="Email"
            placeholder="example@gmail.com"
            disabled={isLoading}
            type="email"
            onKeyPress={(e) => {
              if (e.key === "Enter" && !!watchAll.email && !errors.email) {
                onSubmit();
              }
            }}
          />
        </Box>
      )}

      {!!watchAll.meetup && (
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!watchAll.email || !!errors.email}
        >
          Next
        </Button>
      )}
    </>
  );
};
