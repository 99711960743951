import { ErrorMessage } from "@hookform/error-message";
import { Add } from "@mui/icons-material";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Button,
  debounce,
} from "@mui/material";
import { SyntheticEvent, useMemo, useState } from "react";
import { FieldPath, Control, FieldErrors, Controller } from "react-hook-form";
import { useRequest } from "src/hooks/useRequest";
import { ApplyFormData } from "src/pages/application/ApplicationVerifiedView";

export const AddGamesField = ({
  title,
  buttonText,
  fieldLabel,
  fieldName,
  control,
  errors,
  options,
  searchBGG,
}: {
  title: string;
  buttonText: string;
  fieldLabel: string;
  fieldName: FieldPath<ApplyFormData>;
  control: Control<ApplyFormData>;
  errors: FieldErrors<ApplyFormData>;
  options: { value: string; label: string }[];
  searchBGG?: boolean;
}) => {
  const { request: searchGeekRequest } = useRequest({
    method: "GET",
    url: "https://boardgamegeek.com/xmlapi2/search",
    withCredentials: false,
  });

  const [showField, setShowField] = useState(false);
  //   const [searchQuery, setSearchQuery] = useState("");

  const search = async (query: string) => {
    const res = await searchGeekRequest({ type: "boardgame", query });

    try {
      const parser = new DOMParser();
      let parsed = parser.parseFromString(res as string, "application/xml");
      const result: { id: string; name: string }[] = [];
      parsed.querySelectorAll("[type=boardgame]").forEach((item) => {
        const id = item.getAttribute("id")?.toString();
        const primaryName = item
          .querySelector("name[type=primary]")
          ?.getAttribute("value")
          ?.toString();
        const year = item
          .querySelector("yearpublished")
          ?.getAttribute("value")
          ?.toString();
        if (!!id && !!primaryName && !!year) {
          result.push({ id, name: `${primaryName} (${year})` });
        }
      });
      return result;
    } catch (e) {
      debugger;
    }

    return [];
  };
  const [isSearching, setIsSearching] = useState(false);

  const [searchOptions, setSearchOptions] = useState<typeof options>([]);

  const debouncedSearch = useMemo(() => {
    return debounce(async (query) => {
      setIsSearching(true);
      try {
        if (searchOptions.length > 0) {
          setSearchOptions([]);
        }
        const data = await search(query);
        setSearchOptions(
          data
            .map((item) => ({
              value: item.id.toString(),
              label: item.name,
            }))
            .sort((a, b) => {
              if (
                a.label.toLowerCase().indexOf(query.toLowerCase()) >
                b.label.toLowerCase().indexOf(query.toLowerCase())
              ) {
                return 1;
              } else if (
                a.label.toLowerCase().indexOf(query.toLowerCase()) <
                b.label.toLowerCase().indexOf(query.toLowerCase())
              ) {
                return -1;
              } else {
                if (a.label > b.label) return 1;
                else return -1;
              }
            })
        );
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setIsSearching(false);
      }
    }, 1000);
  }, [searchOptions, setSearchOptions, debounce]);

  const handleInputChange = (e: SyntheticEvent, value: string) => {
    if (!searchBGG) {
      return;
    }
    if (value === "") {
      setSearchOptions([]);
    } else if (value.length > 2) {
      setIsSearching(true);
      debouncedSearch(value);
    }
  };

  const actualOptions = useMemo(() => {
    if (!!searchBGG) {
      return searchOptions;
    }
    return options;
  }, [options, searchBGG, searchOptions]);

  return (
    <Box>
      <Typography variant="body2" fontWeight={500}>
        {title}
      </Typography>
      {showField && (
        <Box sx={{ mt: 2 }}>
          <Controller
            name={`${fieldName}`}
            control={control}
            // defaultValue={field.name}
            render={({ field }) => (
              <>
                {/* {JSON.stringify(actualOptions)} */}
                <Autocomplete
                  {...field}
                  onChange={(e, value, reason) => {
                    field.onChange(value);
                  }}
                  value={field.value as typeof options}
                  //   value={field.value as string[]}
                  // groupBy={def.groupOptions ? (option) => option.group! : undefined}
                  options={actualOptions}
                  filterOptions={searchBGG ? (x) => x : undefined}
                  multiple
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  noOptionsText={
                    isSearching
                      ? "Searching..."
                      : "No games (type to search...)"
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    typeof option === "string"
                      ? option
                      : option.label || option.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={fieldLabel}
                      variant="outlined"
                      //   size="small"
                      fullWidth
                      error={!!(errors as any)[fieldName]}
                      helperText={
                        <ErrorMessage errors={errors} name={fieldName} />
                      }
                    />
                  )}
                  onInputChange={handleInputChange}
                />
                {/* {JSON.stringify(field.value)} */}
              </>
            )}
          />
        </Box>
      )}
      {!showField && (
        <Button
          // variant="outlined"
          size="small"
          startIcon={<Add />}
          onClick={() => setShowField(true)}
          sx={{ color: "primary.main", mr: 2 }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
