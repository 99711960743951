import { Box, styled, Typography } from "@mui/material";
import { useLocation, useMatch } from "react-router-dom";
import { ROUTES } from "src/consts/routes";

export const HomeHeader = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const isHomePage = pathname === "/";

  return (
    <StyledRoot>
      <StyledBackground />
      <StyledBox
        sx={{
          background: isHomePage ? `#250a4099` : `#250a40cc`,
          padding: isHomePage ? undefined : 0,
          position: "relative",
        }}
      >
        <StyledLogo
          href="\"
          sx={{
            padding: isHomePage ? undefined : "1em",
          }}
        >
          <Box sx={{ flex: 0 }}>
            <Box
              component="img"
              src={process.env.PUBLIC_URL + "/icon64.png"}
              sx={{
                display: "block",
                height: isHomePage ? "2em" : "2.5em",
                mx: "auto",
              }}
            />
          </Box>
          {isHomePage && (
            <Box>
              <Box
                sx={{
                  flex: 0,
                  whiteSpace: "nowrap",
                  color: "#fff",
                }}
              >
                <TitleSpan color="#28e3e0">Warsaw </TitleSpan>
                <TitleSpan color="#e2d826">Board </TitleSpan>
                <TitleSpan color="#ff792e">Games </TitleSpan>
              </Box>
              <StyledEnthusiasts>enthusiasts</StyledEnthusiasts>
            </Box>
          )}
        </StyledLogo>
        {isHomePage && (
          <StyledDescription>
            <Typography variant="subtitle2" fontWeight={500}>
              International board game community
              <br />
              in Warsaw, Poland
            </Typography>
          </StyledDescription>
        )}
        {process.env.REACT_APP_ENVIRONMENT === "development" && (
          <Typography
            sx={{
              textAlign: "center",
              p: 1,
              color: "#ffff",
              textTransform: "uppercase",
              fontWeight: 800,
              fontSize: "10vw",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",

              zIndex: "-1",
            }}
          >
            development
          </Typography>
        )}
      </StyledBox>
    </StyledRoot>
  );
};

const StyledRoot = styled("div")({ position: "relative" });

const StyledBackground = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: `100%`,
  height: `100%`,
  zIndex: `-1`,
  background: `url(${process.env.PUBLIC_URL + "/header.jpg"})`,
  backgroundSize: `100% auto`,
  backgroundPosition: `50% 50%`,
  filter: `blur(2px) grayscale(1) opacity(0.70)`,
});

const StyledBox = styled("div")({
  background: `#250a4099`,
  padding: `32px`,
});

const SHADOW_SIZE = 2;
const SHADOW_COLOR = "#000";

const StyledLogo = styled("a")({
  display: `flex`,
  justifyContent: `center`,
  flexDirection: "column",
  textAlign: "center",
  alignItems: `center`,
  gap: `16px`,
  fontSize: `24px`,
  fontWeight: `600`,
  marginBottom: `24px`,
  textDecoration: "none",
  filter: `
  drop-shadow(${SHADOW_SIZE}px ${SHADOW_SIZE}px 0px ${SHADOW_COLOR}) 
  `,
  transition: "0.5s",
  "&:hover": {
    opacity: 0.5,
  },
});

const StyledDescription = styled("div")({
  mb: 0,
  textAlign: "center",
  color: "#fff",
  textShadow: `2px 2px 0 #000`,
});
const StyledEnthusiasts = styled("div")({
  color: "#fff",
  fontWeight: "200",
  textTransform: "uppercase",
  fontSize: "0.8em",
  letterSpacing: "8px",
  marginTop: "8px",
});

const TitleSpan = styled("span")`
  color: ${(props) => props.color};
`;
